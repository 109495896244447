import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import "./menuContent.scss";

const styles = theme => ({
  signin: {
    width: "109px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 15px 0 80px",
    textTransform: "none"
  },
  signin1: {
    width: "109px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 15px 0 80px",
    textTransform: "none",
    backgroundImage: "linear-gradient(to right, #3e1dde, #34a5ec)",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)"
  },
  freetrial: {
    width: "153px",
    //  backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    boxShadow: "0 10px 20px 0 rgba(0,0,0,0.15)",
    backgroundImage: "linear-gradient(to right, #f45062, #f48293)",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 px 0 80px",
    textTransform: "none"
  }
});

class MenuContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ishome, classes } = this.props;
    return (
      <div className="menu">
        <div className={ishome ? " menuItem" : " menuItem1"}>
          <NavLink to="/cases">Cases</NavLink>
        </div>
        <div className={ishome ? "menuItem" : " menuItem1"}>
          <NavLink to="/blog">Blog</NavLink>
        </div>
        <div className={ishome ? " menuItem" : " menuItem1"}>
          <NavLink to="/faq">FAQ</NavLink>
        </div>
        <div className={ishome ? " menuItem" : "menuItem1"}>
          {/* <NavLink to="/company">Company</NavLink> */}
        </div>

        <Button
          className={ishome ? classes.signin : classes.signin1}
          onClick={this.openLogin}
        >
          Sign In
        </Button>

        <Button className={classes.freetrial} onClick={this.openTrial}>
          Risk Free Trial
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(MenuContent);
