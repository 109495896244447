import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import DotLoader from "react-spinners/DotLoader";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import { Refresh } from "../../components/Icon";
import axios from "axios";
import _ from "lodash";
import styled from "styled-components";
import WizardPopup from "../../components/WizardPopup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";

import "./index.scss";

const styles = theme => ({
  findButtonIcon: {
    height: "14px",
    margin: "0 0 0 10px"
  },
  findButton: {
    width: "100%",
    height: "100%",
    color: "#1d48f5",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreBusinessButtonIcon: {
    height: "20px",
    margin: "0 10px 0 0px",
    color: "#1d48f5"
  },
  downloadButton: {
    padding: "10px 30px",
    color: "#ffffff",
    backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    fontFamily: "DM Sans"
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      url: "",
      resultData: [],
      openPopup: false,
      kw_in_title_tag: false,
      kw_in_header_Tag: false,
      kw_in_first_p: false,
      kw_in_alt_tag: false,
      kw_in_file_name: false,
      kw_to_text: 0,
      relavancy_score: 0,
      kw_volume: 0
    };
  }

  componentDidMount() {
    console.log("this.props.location", this.props.location);
    if (this.props.location.keyword === undefined) {
      this.props.history.push({
        pathname: `/seo-wizard`
      });
    } else {
      this.setState({
        keyword: this.props.location.keyword,
        url: this.props.location.url,
        openPopup: true
      });
      axios
        .get(
          "https://o7cw7k7s9c.execute-api.us-east-1.amazonaws.com/staging?keyword=" +
            this.props.location.keyword +
            "&url=" +
            this.props.location.url
        )
        .then(res => {
          this.setState({
            resultData: res.data,
            kw_in_title_tag: res.data.kw_in_title_tag,
            kw_in_header_Tag: res.data.kw_in_header_Tag,
            kw_in_first_p: res.data.kw_in_first_p,
            kw_in_alt_tag: res.data.kw_in_alt_tag,
            kw_in_file_name: res.data.kw_in_file_name,
            kw_to_text: res.data.my_average,
            relavancy_score: res.data.relavancy_score,
            kw_volume: res.data.kw_volume
          });
        });
      var res = {
        data: {
          kw_in_title_tag: false,
          kw_in_header_tag: false,
          kw_in_first_p: true,
          kw_in_alt_tag: true,
          kw_in_file_name: false,
          relavancy_score: 0,
          kw_volume: 0
        }
      };
      this.setState({
        resultData: res.data,
        kw_in_title_tag: res.data.kw_in_title_tag,
        kw_in_header_tag: res.data.kw_in_header_tag,
        kw_in_first_p: res.data.kw_in_first_p,
        kw_in_alt_tag: res.data.kw_in_alt_tag,
        kw_in_file_name: res.data.kw_in_file_name,
        kw_to_text: res.data.my_average,
        relavancy_score: res.data.relavancy_score,
        kw_volume: res.data.kw_volume
      });
    }
  }

  onClose = e => {
    this.setState({ openPopup: false });
  };

  render() {
    const {
      resultData,
      kw_in_title_tag,
      kw_in_header_tag,
      kw_in_first_p,
      kw_in_alt_tag,
      kw_in_file_name,
      kw_to_text,
      relavancy_score,
      kw_volume
    } = this.state;
    const { classes } = this.props;
    var loading = resultData.length === 0 ? true : false;
    const score =
      ((kw_in_title_tag +
        kw_in_title_tag +
        kw_in_first_p +
        kw_in_alt_tag +
        kw_in_file_name) /
        5) *
      100;
    const kw_in_title =
      kw_in_title_tag === false
        ? "Please consider adding your keyword here to improve your page's relevancy for your chosen keyword. For live website example of this and further tips on incorporating your keyword into your page's title, we invite you to donwload our Free SEO Guide below."
        : "looks good, no changes needed";
    const kw_in_p =
      kw_in_first_p === false
        ? "Please consider adding your keyword here to improve your page's relevancy for your chosen keyword. For live website example of this and further tips on incorporating your keyword into your page's title, we invite you to download our Free SEO Guide below."
        : "looks good, no changes needed";
    const kw_in_alt =
      kw_in_alt_tag === false
        ? "Please consider adding your keyword here to improve your page's relevancy for your chosen keyword. For details on how to further optimize your image's alt text, along with a wealth of other optimization tips to help your page rank higher, we invite you to download our Free SEO Guide below."
        : "looks good, no changes needed";
    const kw_in_file =
      kw_in_file_name === false
        ? "Please consider including your keyword in the name of the file itself to further improve your page's overall relevancy for your chosen keyword."
        : "looks good, no changes needed";
    const kw_in_header =
      kw_in_header_tag === false
        ? "Please consider adding your keyword here to improve your page's relevancy for your chosen keyword. For details on how to further optimize your page's h1, h2 and h3 tags, along with a wealth of other optimization tips to help your page rank higher, we invite you to download our Free SEO Guide below."
        : "looks good, no changes needed";

    console.log(kw_in_header_tag);
    console.log(kw_in_header);
    return (
      <div>
        <WizardPopup open={this.state.openPopup} onClose={this.onClose} />
        <div className="">
          <LoadingOverlay
            active={loading}
            spinner={
              <DotLoader
                sizeUnit={"px"}
                css={"margin-bottom:0px;"}
                size={100}
                color={"#1d48f5"}
              />
            }
          >
            <div className="topicfinder">
              <div className="container row">
                <div className="col-12 col-xl-6 d-flex flex-column justify-content-center">
                  <p className="topicFinderResult__title1">
                    Great choice, your keyword has {kw_volume} searches per
                    month (US Google)
                  </p>
                </div>
                <div className="col-12 col-xl-6 d-flex justify-content-center">
                  <img
                    style={{
                      width: "100%",
                      maxWidth: "570px",
                      margin: "0 0 70px 0px"
                    }}
                    src={require("../../assets/images/2480265.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="container seoWizardResult">
              <div className="seoWizardResult__header">
                <p className="seoWizardResult__header__text">
                  May we suggest the following improvement
                </p>
                <div className="seoWizardResult__header__relavancy">
                  <p className="seoWizardResult__header__relavancyText">
                    Your web page relavancy
                    <br /> score for this keyword is:
                  </p>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "50px",
                      marginLeft: "20px"
                    }}
                  >
                    <CircularProgressbar
                      value={relavancy_score}
                      text={`${relavancy_score}%`}
                      styles={buildStyles({
                        textSize: "27px",
                        textColor: "#415089",
                        pathColor: "#3894e0",
                        trailColor: "#c7c9de"
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="seoWizardResult__relavancy">
                <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-in-title.svg")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Keyword in Title
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_title_tag
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        {kw_in_title}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-in-header-one.svg")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Keyword in Header 1 Tag
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_header_tag
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        {kw_in_header}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-in-first.png")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Keyword in First Sentense
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_header_tag
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        {kw_in_p}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-top-10.svg")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Word Count Compare to Top 10 Results
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_title_tag
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        Your page has 342 words of content. These everage word
                        count for top 10 results in Google is 2,100. Cosider
                        writing more content to improve your page’s value vs
                        completing pages for your chosen keyword in Google.
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-count-ratio.svg")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Keyword to Word Count Ratio
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_title_tag
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        Missing keyword to word count ratio is {kw_to_text}%.
                        Consider mentioning your keyword more often in your copy
                        to improve your page’s relevancy for this keyword in
                        Google.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-in-image-alt-text.svg")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Keyword in Image Alt Text
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_alt_tag
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        {kw_in_alt}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="seoWizardResult__relavancy__item container">
                  <div className="row">
                    <div className="col-10 col-md-5 d-flex align-items-center">
                      <img
                        style={{
                          width: "40px",
                          height: "40px"
                        }}
                        src={require("../../assets/SVG/keyword-in-image-file-name.svg")}
                        alt=""
                      />
                      <p className="seoWizardResult__relavancy__item__title">
                        Keyword in Image File Name
                      </p>
                    </div>
                    <div className="col-2 col-md-1 d-flex align-items-center">
                      <img
                        style={{
                          width: "24px !important",
                          height: "24px"
                        }}
                        src={
                          kw_in_file_name
                            ? require("../../assets/SVG/check_.svg")
                            : require("../../assets/SVG/close.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <p className="seoWizardResult__relavancy__item__text">
                        {kw_in_file}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="seoWizardResultGuid">
              <div className="container text-center">
                <div className="seoWizardResultGuid__underline " />
                <p className="seoWizardResultGuid__title">
                  Recomendation for our On-Page SEO guide
                </p>
                <p className="seoWizardResultGuid__smalltitle">
                  Use Synonyms, add modifires, add links to authority sites.
                </p>
                <p className="seoWizardResultGuid__text">
                  Download our on-page seo guide to learn the advanced SEO
                  stratagies we use on our agency to optimize pages for some pf
                  the most comparative keywords, This Guide is full of
                  incretdible insights that are the continuosly updated to stay
                  corrent offer of informations. Its our gift to you
                </p>
                <Button className={classes.downloadButton}>
                  <span className="seoWizardResultGuid__buttontext">
                    <Link
                      style={{ color: "#fff", textDecoration: "none" }}
                      to="/gmb.pdf"
                      target="_blank"
                      download
                    >
                      Download our Definitive Guide
                    </Link>
                  </span>
                </Button>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(index);
