import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./containers/layout/Navbar";
import Footer from "./containers/layout/Footer";
// import SignIn from "./containers/auth/SignIn";
// import SignUp from "./containers/auth/SignUp";
import Dashboard from "./containers/Dashboard";
import Cases from "./containers/Cases";
import Blog from "./containers/Blog";
import SingleBlog from "./containers/SingleBlog";
import Faq from "./containers/Faq";
import Analyze from "./containers/Analyze";
import Home from "./containers/Home";
import Intercom from "react-intercom";

import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import Webinar from "./containers/Webinar";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Profile from "./containers/Profile";
import Billing from "./containers/Billing";
import Company from "./containers/Company";
import Testimonials from "./containers/Testimonials";
import TopicFinder from "./containers/TopicFinder";
import TopicFinderResult from "./containers/TopicFinderResult";
import SeoWizard from "./containers/SeoWizard";
import SeoWizardResult from "./containers/SeoWizardResult";

class App extends Component {
  render() {
    const { auth } = this.props;
    const user = {
      user_id: auth.uid,
      email: auth.email
    };

    return (
      <BrowserRouter>
        <ScrollToTopRoute>
          <div className="App">
            <Navbar />
            <Switch>
              {/* <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} /> */}
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/cases" component={Cases} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:id" component={SingleBlog} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/analyze" component={Analyze} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/webinar_19" component={Webinar} />
              <Route exact path="/company" component={Company} />
              <Route exact path="/testimonials" component={Testimonials} />
              <Route exact path="/topic-finder" component={TopicFinder} />

              <Route
                exact
                path="/topic-finder/:id"
                component={TopicFinderResult}
              />
              <Route exact path="/seo-wizard" component={SeoWizard} />
              <Route
                exact
                path="/seo-wizard/:id/:id"
                component={SeoWizardResult}
              />
              <Route exact path="/seo-wizard/:id" component={SeoWizardResult} />
            </Switch>
            <Intercom appID="ypd6hocz" {...user} />

            <Footer />
          </div>
        </ScrollToTopRoute>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    paid: state.firebase.profile.paid
  };
};

export default compose(connect(mapStateToProps)(App));
