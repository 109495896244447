import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import LoadingOverlay from "react-loading-overlay";
import DotLoader from "react-spinners/DotLoader";
import { withCookies } from "react-cookie";
import { firestoreConnect } from "react-redux-firebase";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import { Refresh } from "../../components/Icon";
import axios from "axios";
import _ from "lodash";
import styled from "styled-components";
import TopicPopup from "../../components/TopicPopup";

import "./index.scss";

const styles = theme => ({
  findButtonIcon: {
    height: "14px",
    margin: "0 0 0 10px"
  },
  findButton: {
    width: "100%",
    height: "100%",
    color: "#1d48f5",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreBusinessButtonIcon: {
    height: "20px",
    margin: "0 10px 0 0px",
    color: "#1d48f5"
  },
  learnMoreBusinessButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& svg": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& svg": {
        display: "block"
      },
      "& img": {
        display: "none"
      }
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      resultData: [],
      loadmore: false,
      openPopup: false
    };
  }

  onClickLoadmore = () => {
    const cookies = this.props.cookies.cookies._hjid;
    const { emails } = this.props;
    this.setState({
      openPopup: true
    });
    Object.values(emails).map((item, i) => {
      if (item.cookies === cookies) {
        this.setState({
          openPopup: false,
          loadmore: true
        });
      }
    });
  };

  componentDidMount() {
    if (this.props.location.keyword) {
      this.setState({
        keyword: this.props.match.params.id,
        openPopup: false
      });
      axios
        .get(
          "https://ymhhvgllda.execute-api.us-east-1.amazonaws.com/staging?topic=" +
            this.props.match.params.id
          //    +  this.props.location.keyword
        )
        .then(res => {
          this.setState({ resultData: res.data });
        });
    } else {
      this.setState({
        keyword: this.props.location.keyword,
        openPopup: false
      });
      axios
        .get(
          // "https://ymhhvgllda.execute-api.us-east-1.amazonaws.com/staging?topic={keyword}"
          "https://ymhhvgllda.execute-api.us-east-1.amazonaws.com/staging?topic=" +
            this.props.location.keyword
        )
        .then(res => {
          this.setState({ resultData: res.data });
        });
    }
  }

  onClose = e => {
    this.setState({ openPopup: false });
  };

  onLoadmore = e => {
    this.setState({ openPopup: false, loadmore: !this.state.loadmore });
  };

  render() {
    const { loadmore, resultData } = this.state;
    const { classes } = this.props;
    var loading = resultData.length === 0 ? true : false;

    return (
      <div>
        <TopicPopup
          open={this.state.openPopup}
          onClose={this.onClose}
          onLoadmore={this.onLoadmore}
          cookie={this.props.cookies.cookies._hjid}
        />
        <div className="">
          <LoadingOverlay
            active={loading}
            spinner={
              <DotLoader
                sizeUnit={"px"}
                css={"margin-bottom:0px;"}
                size={100}
                color={"#1d48f5"}
              />
            }
          >
            <div className="topicfinder">
              <div className="container row">
                <div className="col-12 col-xl-6 d-flex flex-column justify-content-center">
                  <p className="topicFinderResult__title">
                    Choose one (1) keyword and include it in your title and blog
                    post to help your content be found on Google.
                  </p>
                </div>
                <div className="col-12 col-xl-6 d-flex justify-content-center">
                  <img
                    style={{
                      width: "100%",
                      maxWidth: "570px",
                      margin: "0 0 30px 0px"
                    }}
                    src={require("../../assets/images/artboard.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="container topicFinderResult">
              <div className="topicFinderResult__header">
                <div className="row">
                  <p className="col-5 col-sm-8 topicFinderResult__header__keyword">
                    KEYWORD
                  </p>
                  <p className="col-4 col-sm-2">VOLUME</p>
                  <p className="col-3 col-sm-2">DIFFILCULTY</p>
                </div>
              </div>
              <div
                className="topicFinderResult__body"
                style={
                  !loadmore ? { maxHeight: "1000px", overflow: "hidden" } : {}
                }
              >
                {resultData.length > 0 &&
                  resultData.map((item, key) => (
                    <div
                      key={key}
                      className="row topicFinderResult__body__item"
                    >
                      {/* <div className="col-8 d-flex align-items-center">
                      <Checkbox
                        //  checked={}
                        disabled={true}
                        //     onChange={event => props.selectRow(event, row)}
                      />
                      <p>{item.keyword}</p>
                    </div> */}
                      <p
                        className="col-6 col-sm-8"
                        style={{ padding: "20px 10px 20px 30px" }}
                      >
                        {item.keyword}
                      </p>
                      <p className="col-3 col-sm-2">{item.volume}</p>
                      <p className="col-3 col-sm-2">
                        {item.difficulty > 66
                          ? "Hard"
                          : item.difficulty > 33
                          ? "Medium"
                          : "Easy"}
                      </p>
                    </div>
                  ))}

                {!loadmore && resultData.length > 10 && (
                  <div className="review__hide">
                    <Button
                      type="button"
                      className={classes.learnMoreBusinessButton}
                      onClick={this.onClickLoadmore}
                    >
                      <img
                        className={classes.learnMoreBusinessButtonIcon}
                        src={require("../../assets/SVG/refresh.svg")}
                        alt=""
                      />
                      <Refresh
                        className={classes.learnMoreBusinessButtonIcon}
                      />
                      View More
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    emails: state.firestore.data.emails
  };
};

export default withCookies(
  compose(
    connect(mapStateToProps),
    firestoreConnect([
      {
        collection: "emails"
      }
    ])
  )(withStyles(styles)(index))
);
