import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Tabs,
  Tab,
  Typegraphy,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox
} from "@material-ui/core";

import { Elements, StripeProvider } from "react-stripe-elements";
import axios from "axios";
import _ from "lodash";
import CheckoutForm from "../../components/CheckoutForm";
import HomeTableRow from "../../components/HomeTableRow";
import "./styles.scss";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8"
  },
  tabsIndicator: {
    backgroundColor: "#1890ff"
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#40a9ff"
    }
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3
  },
  easyrow: {
    backgroundColor: "#E9FFE5"
  },
  mediumrow: {
    backgroundColor: "#FFFDEA"
  },
  hardrow: {
    backgroundColor: "#FFEDED"
  }
});

class Home extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      keyword: null,

      options: {},
      series: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.urlChange = this.urlChange.bind(this);
  }

  urlChange(e) {
    this.setState({
      url: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.url !== "") {
      this.props.history.push({
        pathname: "/analyze",
        url: this.state.url
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.keyword !== this.state.keyword) {
      const baseUrl = // "https://3d0fhhvlx0.execute-api.us-east-1.amazonaws.com/staging?keyword=montreal%20visiting%20places&client_id=UID"
        "https://3d0fhhvlx0.execute-api.us-east-1.amazonaws.com/staging?keyword=" +
        nextState.keyword.key +
        "&client_id=" +
        this.props.auth.uid;
      axios.get(baseUrl).then(res => {
        // this.setState({ categoriesData: Object.keys(res.data), seriesData: Object.values(res.data) });
        this.setState({
          options: {
            chart: {
              id: "apexchart-example"
            },
            xaxis: { categories: Object.keys(res.data) }
          },
          series: [
            {
              name: "Point",
              data: Object.values(res.data)
            }
          ]
        });
      });
    }
  }

  render() {
    const { auth, profile } = this.props;
    const { isLoaded, paid } = profile;
    if (!isLoaded) return null;
    if (!auth.uid) return <Redirect to="/" />;
    const data = profile.keywords || [];

    return (
      <div className="home">
        <div className="container">
          {paid ? (
            <div>
              <p className="mainText">
                Welcome back, {this.props.profile.username}!
              </p>
              <div className="row mainTableTitle">
                <div className="col-md-3 d-md-block d-sm-none d-none">
                  KEYWORD
                </div>
                <div className="col-md-2 d-md-block d-sm-none d-none">
                  POSITION
                </div>
                <div className="col-md-2 d-md-block d-sm-none d-none">
                  CHANGE
                </div>
                <div className="col-md-3 d-md-block d-sm-none d-none">URL</div>
              </div>
              <div>
                {data.length &&
                  data.map((row, index) => (
                    <HomeTableRow row={row} key={index} auth={auth} />
                  ))}
              </div>
            </div>
          ) : (
            <div>
              <StripeProvider apiKey="pk_live_uKfjQC8GOuQelg01OjwRNwq7">
                <div className="checkoutPayment">
                  <Elements>
                    <CheckoutForm
                      selectedKeywords={profile.keywords}
                      auth={auth}
                      paid={paid}
                    />
                  </Elements>
                </div>
              </StripeProvider>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notifications: state.firestore.ordered.notifications
  };
};

const StyledHome = withStyles(styles)(Home);

export default compose(
  connect(mapStateToProps)
  // firestoreConnect([
  //   { collection: "notifications", limit: 3, orderBy: ["time", "desc"] }
  // ])
)(StyledHome);

// export default Dashboard;
