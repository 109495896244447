import React from "react";
import PropTypes from "prop-types";
import lifecycle from "react-pure-lifecycle";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import ReactAwesomePopover from "react-awesome-popover";
import ReactTooltip from "react-tooltip";
import "./style.scss";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "key",
    numeric: false,
    disablePadding: true,
    label: "Keyword"
  },
  { id: "position", numeric: true, disablePadding: false, label: "Position" },
  {
    id: "search_volume",
    numeric: true,
    disablePadding: false,
    label: "Volume"
  },
  {
    id: "competition",
    numeric: true,
    disablePadding: false,
    label: "Difficulty"
  },
  { id: "url", numeric: true, disablePadding: false, label: "Link" }
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing.unit * 2
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  easyrow: {
    backgroundColor: "#E9FFE5",
    borderLeft: "solid 3px #21ca4b",
    padding: "15px 0"
  },
  mediumrow: {
    backgroundColor: "#FFFDEA",
    borderLeft: "solid 3px #f4c450",
    padding: "15px 0"
  },
  hardrow: {
    backgroundColor: "#FFEDED",
    borderLeft: "solid 3px #f45062",
    padding: "15px 0"
  }
});

const Popover = ReactAwesomePopover;

const methods = {
  componentDidUpdate(props) {
    ReactTooltip.rebuild();
    console.log("tooltip rebuild");
  }
};

function EnhancedTable(props) {
  const { classes } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // function handleRequestSort(event, property) {
  //   const isDesc = orderBy === property && order === "desc";
  //   setOrder(isDesc ? "asc" : "desc");
  //   setOrderBy(property);
  // }

  // function handleSelectAllClick(event) {
  //   if (event.target.checked) {
  //     const newSelecteds = props.data.map(n => n.key);
  //     // props.selectedKeywordArray = props.data;
  //     props.setSelectedKeywordArray(props.data);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  //   props.setSelectedKeywordArray([]);
  // }

  function handleClick(event, row) {
    const selectedIndex = selected.indexOf(row.key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    props.selectRow(event, row);

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    ReactTooltip.rebuild();
    console.log("change pages");
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    ReactTooltip.rebuild();
    console.log("change rows");
  }

  const isSelected = key => selected.indexOf(key) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);
  const medium = 0.33;
  const hard = 0.66;

  return (
    <div className="container" style={{ width: "100%", padding: "0px" }}>
      <Paper
        style={{
          width: "100%",
          marginBottom: 20
          //    padding: "15px"
          //      borderLeft: "solid 3px #21ca4b"
        }}
      >
        <div
          style={{ width: "minWidth: 750", fontSize: "18px", color: "#7e849d" }}
        >
          <Table
            style={{ overflowX: "auto", width: "100%" }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            {/* <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            /> */}
            <TableBody>
              {stableSort(props.data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const isItemSelected = isSelected(row.key);
                  return (
                    //  <Popover action="hover" placement="right">

                    <TableRow
                      hover
                      onClick={event =>
                        row.competition > hard ? null : handleClick(event, row)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.key}
                      className={
                        row.competition > hard
                          ? classes.hardrow
                          : row.competition > medium
                          ? classes.mediumrow
                          : classes.easyrow
                      }
                      selected={isItemSelected}
                      data-tip
                      data-for={
                        row.competition > hard
                          ? "hardKeyword"
                          : row.competition > medium
                          ? "mediumKeyword"
                          : "easyKeyword"
                      }
                    >
                      <TableCell
                        component="th"
                        padding="checkbox"
                        className="t__checkbox"
                      >
                        <Checkbox
                          checked={isItemSelected}
                          disabled={row.competition > hard ? true : false}
                          //     onChange={event => props.selectRow(event, row)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <span className="t__key">{row.key}</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="t__volume">
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          {row.search_volume}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="t__position">
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          {row.position}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        {row.competition > hard ? (
                          <span className="t__difficult">
                            Hard&nbsp;&nbsp;&nbsp;
                          </span>
                        ) : row.competition > medium ? (
                          <span className="t__difficult"> Medium</span>
                        ) : (
                          <span className="t__difficult">
                            Easy&nbsp;&nbsp;&nbsp;
                          </span>
                        )}
                      </TableCell>
                      {/* <TableCell align="right">{row.url}</TableCell> */}
                      <TableCell padding="none">
                        <ReactTooltip
                          place="right"
                          type="success"
                          effect="solid"
                          id="easyKeyword"
                          className="tooltipTextEasy"
                        >
                          <span>
                            Ranking improvements will happen quickly for this
                            group of keywords.
                          </span>
                        </ReactTooltip>
                        <ReactTooltip
                          place="right"
                          id="mediumKeyword"
                          type="success"
                          effect="solid"
                          className="tooltipTextMedium"
                        >
                          <span>
                            Ranking improvements will happen slower but you will
                            still see results over time. We recommend booster
                            packs to speed things up! You will be able to order
                            these from your account.
                          </span>
                        </ReactTooltip>
                        <ReactTooltip
                          place="right"
                          id="hardKeyword"
                          type="success"
                          effect="solid"
                          className="tooltipTextHard"
                          delayHide={500}
                        >
                          <span>
                            Due to the extremely competitive nature of this
                            keyword it will require extensive on-site
                            optimization and a greater number of backlinks to
                            reach page 1 of Google. Please contact us at
                            welcome@onekeyword.com so that we can you help
                            achieve your business goals.
                          </span>
                        </ReactTooltip>
                      </TableCell>
                    </TableRow>
                  );
                  {
                    /* <div className="popup">
                        <p className="popup__text">
                          Ranking improvements will happen slower but you will
                          still see results over time. We recommend booster
                          packs to speed things up! You will be able to order
                          these from your account.
                        </p>
                      </div>
                    </Popover> */
                  }
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default lifecycle(methods)(withStyles(useStyles)(EnhancedTable));
