import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import MenuDialog from "./Menu";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { IconButton, withStyles } from "@material-ui/core";
import logo from "../../assets/SVG/logo.svg";
import logo1 from "../../assets/SVG/logo1.svg";
// import menu from "../../assets/SVG/menu.svg";

import { renderComponent } from "recompose";
import { Menu } from "../../components/Icon";

const LogoIcon = styled.img`
  width: 161px;
  height: 45px;
`;

const styles = {
  // WrapperShadow: {
  //   width: "100%",
  //   height: 96,
  //   position: "absolute",
  //   top: -6,
  //   zIndex: -1,
  //   boxShadow: "0 0 78.1px 5.9px rgba(37, 60, 158, 0.46)",
  //   borderRadius: "0 0 50% 50%",
  // },
  content: {
    maxWidth: 1000,
    margin: "padding: 40px 0"
  }
};

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      menuOpen: false
    };
    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentWillMount() {
    this.updateScreenWidth();
  }

  updateScreenWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  openMenu = () => {
    this.setState({ menuOpen: true });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    const { width } = this.state;
    const home = this.props.location.pathname === "/" ? true : false;
    const { auth, profile } = this.props;
    const links = auth.uid ? (
      <SignedInLinks profile={profile} />
    ) : (
      <SignedOutLinks ishome={home} />
    );
    return (
      <div
        style={
          auth.uid && !profile.paid
            ? { opacity: "0.5", boxShadow: "0 3px 2px 0 rgba(0,4,77,0.2)" }
            : { boxShadow: "0 3px 2px 0 rgba(0,4,77,0.2)" }
        }
      >
        {!auth.uid && (
          <MenuDialog
            open={this.state.menuOpen}
            onClose={this.closeMenu}
            auth={auth}
          />
        )}

        <nav
          className="nav-wrapper grey darken-3 container position-relative "
          style={{ zIndex: "1" }}
        >
          <div
            className="row justify-content-between"
            style={{ padding: "25px 0" }}
          >
            {home ? (
              <NavLink to="/">
                <LogoIcon src={logo} alt="" style={{ marginLeft: "30px" }} />
              </NavLink>
            ) : (
              <NavLink to="/">
                <LogoIcon src={logo1} alt="" style={{ marginLeft: "30px" }} />
              </NavLink>
            )}
            {width > 991 ? (
              links
            ) : !auth.uid ? (
              <IconButton
                style={{ marginRight: "10px" }}
                onClick={this.openMenu}
              >
                <Menu
                  alt=""
                  style={
                    home
                      ? { width: "30px", fill: "#ffffff" }
                      : { width: "30px", fill: "#1d48f5" }
                  }
                />
              </IconButton>
            ) : (
              <SignedInLinks profile={profile} />
            )}
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

// export default withStyles(styles)(connect(mapStateToProps)(Navbar));
export default compose(
  withRouter,
  connect(mapStateToProps),
  withStyles(styles)
)(Navbar);
