import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import Login from "../../../components/Login";
import { connect } from "react-redux";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { IntercomAPI } from "react-intercom";

import {
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  LinkedIn
} from "../../../components/Icon";
import "./styles.scss";
const LogoIcon = styled.img`
  width: 16px;
  height: 16px;
  filter: invert(1);
  opacity: 0.5;
`;

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.setState({
      open: false
    });
  }

  onPrice() {
    this.props.history.push({
      pathname: "/faq",
      state: {
        question: "Only $199 a month? How? There must be a catch!"
      }
    });
  }

  onSupport() {
    console.log("intercome event");
    IntercomAPI("trackEvent", "support-request");
    setTimeout(
      function() {
        IntercomAPI("update");
        console.log("Update");
      }.bind(this),
      1000
    );
  }

  onClose = e => {
    this.setState({ open: false, trial: false });
  };

  render() {
    const { auth, profile } = this.props;
    return (
      <div
        className="footer"
        style={auth.uid && !profile.paid ? { opacity: "0.5" } : null}
      >
        <Login open={this.state.open} onClose={this.onClose} />

        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-sm-6 col-lg-4 col-md-12 center-align text-left">
              <img
                src={require("../../../assets/SVG/logo.svg")}
                alt=""
                className="footer__logo"
              />
              <p className="footer__logo__text">
                Rank any keyword at the top of Google for $199 / month. Enjoy
                peace of mind with our higher rankings guarantee. The simplest
                way to grow your business on Google. So easy, it's addictive.{" "}
              </p>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 col-md-4 center-align text-left">
              <p className="footer__title">Product</p>
              <p className="footer__logo__text">
              <NavLink to="/testimonials" style={{  color: '#7e849d' }} >  Testimonials </NavLink>

                <br />
                <NavLink to="/faq" style={{ color: "#7e849d" }}>
                  {" "}
                  FAQ{" "}
                </NavLink>
                <br />
                <NavLink to="/cases" style={{ color: "#7e849d" }}>
                  {" "}
                  Use Cases{" "}
                </NavLink>
              </p>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 col-md-4 center-align text-left">
              <p className="footer__title">Company</p>
              <p className="footer__logo__text">
                <NavLink to="/company" style={{ color: "#7e849d" }}>
                  {" "}
                  About Us{" "}
                </NavLink>
                <br />
                <NavLink to="/blog" style={{  color: '#7e849d' }}> Blog </NavLink>
                <br />
                <span
                  className="footerSupport"
                  onClick={() => this.onSupport()}
                >
                  Support
                </span>

                {/* Careers */}
              </p>
            </div>
            <div className="col-6 col-sm-3 col-lg-3 col-md-4 center-align text-left">
              <p className="footer__title">Contact</p>
              <div className="footer__logo__text">
                17350 Sunset Blvd.
                <br />
                Pacific Palisades, CA 90272
                <br />
                welcome@onekeyword.com
                <br />
                1.877.TOP.SEO1
                <br />
                <br />
                <div style={{ display: "flex ", marginLeft: "-10px" }}>
                  <IconButton
                    className="footer__logo__icon"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/One-Keyword-865678867138786/",
                        "_blank"
                      )
                    }
                  >
                    <Facebook alt="" className="footer__logo__icon__img" />
                    {/* <LogoIcon
                      src={require("../../../assets/SVG/facebook1.svg")}
                      alt=""
                      className="responsive-img"
                    /> */}
                  </IconButton>

                  <IconButton
                    className="footer__logo__icon"
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/channel/UCAmEHktKOZVVF6UdCXDxNNQ",
                        "_blank"
                      )
                    }
                  >
                    <Youtube alt="" className="footer__logo__icon__img" />
                  </IconButton>

                  <IconButton
                    className="footer__logo__icon"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/onekeyword/",
                        "_blank"
                      )
                    }
                  >
                    <Instagram alt="" className="footer__logo__icon__img" />
                  </IconButton>

                  <IconButton
                    className="footer__logo__icon"
                    onClick={() =>
                      window.open("https://twitter.com/one_keyword", "_blank")
                    }
                  >
                    <Twitter alt="" className="footer__logo__icon__img" />
                  </IconButton>

                  <IconButton
                    className="footer__logo__icon"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/one-keyword",
                        "_blank"
                      )
                    }
                  >
                    <LinkedIn alt="" className="footer__logo__icon__img" />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="footer__line" />
            <div className="col-md-m7 center-align text-left">
              <p className="footer__linetext1">©One Keyword LLC.</p>
            </div>
            <div className="col-lg-6 col-md-8 text-right d-flex">
              <p className="footer__linetext" />
              <p className="footer__linetext" />
              <p className="footer__linetext">
                <NavLink to="/privacy" style={{ color: "#7e849d" }}>
                  {" "}
                  Privacy Policy{" "}
                </NavLink>
              </p>
              <p className="footer__linetext">
                <NavLink to="/terms" style={{ color: "#7e849d" }}>
                  {" "}
                  Terms and Condition{" "}
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
