import React, { Component } from "react";
import axios from "axios";
import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ComposedChart,
  Bar
} from "recharts";
import CustomizedDot from "../CustomizedDot";
import CustomizedActiveDot from "../CustomizedActiveDot";
import "./styles.scss";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom_tooltip">
        <p className="custom_tooltip__label">{`${label} : ${
          payload[0].value
        }`}</p>
      </div>
    );
  }

  return null;
};

class HomeTableRow extends Component {
  constructor() {
    super();
    this.state = {
      openGraph: false,
      change: "",
      graphData: [],
      tabledata: [],
      ymax: 100
    };
  }

  componentDidMount() {
    this.getChange();
    const baseUrl =
      "https://3d0fhhvlx0.execute-api.us-east-1.amazonaws.com/staging?keyword=" +
      this.props.row.key +
      "&client_id=" +
      this.props.auth.uid;

    axios.get(baseUrl).then(res => {
      let tabeldatatemp = [];
      let yMaxvalue = 100;

      this.setState({ graphData: res.data });
      //   if (res.data.length > 1 && res.data.length !== undefined) {
      res.data.map(item => {
        if (yMaxvalue < Number(item[Object.keys(item)[0]]))
          yMaxvalue = item[Object.keys(item)[0]];
        tabeldatatemp.push({
          name: Object.keys(item)[0],
          uv: item[Object.keys(item)[0]]
        });
      });
      this.setState({
        tabledata: tabeldatatemp,
        ymax: yMaxvalue
      });
      // } else {
      //   console.log(
      //     "item",
      //     Object.keys(res.data)[0],
      //     res.data[Object.keys(res.data)[0]]
      //   );
      //   if (yMaxvalue < res.data[Object.keys(res.data)[0]]) {
      //     yMaxvalue = res.data[Object.keys(res.data)[0]];
      //   }
      //   tabeldatatemp.push({
      //     name: Object.keys(res.data)[0],
      //     uv: res.data[Object.keys(res.data)[0]]
      //   });
      //   this.setState({
      //     tabledata: tabeldatatemp,
      //     ymax: yMaxvalue
      //   });
      // }
    });
  }

  clickButton = () => {
    this.setState({
      openGraph: !this.state.openGraph
    });
  };

  getChange = () => {
    const baseUrl =
      "https://g4lqn3jqu9.execute-api.us-east-1.amazonaws.com/staging?keyword=" +
      this.props.row.key +
      "&client_id=" +
      this.props.auth.uid;
    axios.get(baseUrl).then(res => {
      this.setState({
        change: res.data.message ? res.data.message : res.data.change
      });
    });
  };

  render() {
    const { row } = this.props;
    const { openGraph, change, graphData, tabledata } = this.state;

    return (
      <div>
        <div className="KeywordRow ">
          <div className="KeywordRowItem row">
            <div className="col-md-3">
              <p>
                <span className="KeywordRowItemTitle d-sm-block d-md-none">
                  KEYWORD: &nbsp;
                </span>
                {row.key}
              </p>
            </div>
            <div className="col-md-2">
              <p>
                <span className="KeywordRowItemTitle d-sm-block d-md-none">
                  POSITION: &nbsp;&nbsp;
                </span>
                {row.position}
              </p>
            </div>
            <div className="col-md-2">
              <p>
                <span className="KeywordRowItemTitle d-sm-block d-md-none">
                  CHANGE: &nbsp;&nbsp;&nbsp;
                </span>
                {change}
              </p>
            </div>
            <div className="col-md-5 d-flex justify-content-between">
              <p style={{ maxWidth: "90%", overflowX: "hidden" }}>
                <span className="KeywordRowItemTitle d-sm-block d-md-none">
                  URL: &nbsp;&nbsp;
                </span>
                {row.url}
              </p>
              <IconButton
                style={{ padding: "0px", height: "30px" }}
                onClick={this.clickButton}
              >
                <img
                  className="KeywordRowItem__img"
                  style={
                    openGraph
                      ? { backgroundColor: "#1d48f5" }
                      : { backgroundColor: "#e8ecfd" }
                  }
                  src={
                    openGraph
                      ? require("../../assets/SVG/graph1.png")
                      : require("../../assets/SVG/graph.png")
                  }
                  alt=""
                />
              </IconButton>
            </div>
          </div>
          {openGraph && graphData.length !== 0 && (
            <div className="keywordGraph1">
              {
                <AreaChart
                  width={1050}
                  height={400}
                  data={tabledata}
                  margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                  type=""
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="rgb(40, 56, 237)"
                        stopOpacity={0.23}
                      />
                      <stop
                        offset="95%"
                        stopColor="rgb(45, 158, 251)"
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2838ed" stopOpacity={0} />
                      <stop
                        offset="95%"
                        stopColor="#2d9efb"
                        stopOpacity={0.23}
                      />
                    </linearGradient>
                    <linearGradient
                      id="colorUv2"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#2838ed" />
                      <stop offset="100%" stopColor="#2d9efb" />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis
                    type="number"
                    domain={[dataMin => 100, dataMax => 1]}
                    ticks={[1,10,20,30,40,50,60,70,80,90,100]}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                    //    position={{ y: 200 }}
                    contentStyle={{ color: "#415089" }}
                    cursor={{
                      stroke: "rgba(40, 56, 237, 0.23)",
                      strokeWidth: 100,
                      strokeStyle: "dashed"
                    }}
                    content={<CustomTooltip />}
                  />
                  <ReferenceLine stroke="green" />

                  <Area
                    type="monotone"
                    dataKey="uv"
                    dot={<CustomizedDot />}
                    activeDot={<CustomizedActiveDot />}
                    stroke="url(#colorUv2)"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                    baseLine={8}
                  />
                  {/* <Bar dataKey="uv" barSize={60} fill="url(#colorUv1)" /> */}
                </AreaChart>
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default HomeTableRow;
