import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";

import { signUpEmail } from "../../store/actions/emailActions";
import { Redirect } from "react-router-dom";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon
} from "react-share";

// import NButton from "../ButtonContainer";
// import InputTextField from "../InputTextField";
// import ErrorMessage from "../ErrorMessage";

// import { loginRequest } from "../../actions/auth";
// import { LOGIN_SUCCESS, LOGIN_FAIL } from "../../constants/auth";

// import signinImg from "../../assets/landing/12.svg";
// import signinImg1 from "../../assets/landing/18.svg";
// import facebookIcon from "../../assets/SVG/facebook-logo.svg";
// import googleIcon from "../../assets/SVG/google-plus-symbol.svg";
import "./styles.scss";

const UserIcon = styled.img`
  width: 27px;
  height: 27;
  filter: invert(1);
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMsg: "",
      open: false
    };
  }

  componentDidMount() {
    this.setState({
      email: ""
    });
  }
  componentWillReceiveProps(nextProps) {
    // const { auth } = nextProps;
    // const { status, error } = auth;
    // if (error) {
    //   this.setState({
    //     errorMsg: auth.error
    //   });
    //   if (status === LOGIN_FAIL) {
    //     this.setState({
    //       usernameError: error.username ? error.username.join() : "",
    //       emailError: error.email ? error.email.join() : ""
    //     });
    //   }
    // }
    // if (status === LOGIN_SUCCESS) {
    //   this.onClose();
    // }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onClose = () => {
    this.setState({
      email: ""
    });
    this.props.onClose();
    this.props.onLoadmore();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn({
      email: this.state.email
    });
    this.props.onClose();
  };

  render() {
    const { open, onClose, history } = this.props;
    const { username, usernameError, errorMsg } = this.state;

    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/" />;
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        maxWidth="lg"
        // style={{ borderRadius: "25px" }}
        className="dialog"
      >
        <DialogContent className="wizardPopup" style={{ padding: "unset" }}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="wizardPopup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>

          <div className="wizardPopupHeader">
            <img
              style={{ height: "290px", width: "40%" }}
              src={require("../../assets/SVG/logo1.svg")}
              alt=""
            />
            <p className="wizardPopupHeader__text">
              Put in your email address below and we’ll send you the guide
              instantly.
            </p>
          </div>
          <div className="wizardPopupBody">
            <div className="next row">
              <p> </p>
              <FacebookShareButton
                url={"onekeyword.com/seo-wizard"}
                quote={"Seo Wizard"}
                className="share-button"
              >
                <FacebookIcon size={75} />
              </FacebookShareButton>

              <LinkedinShareButton
                url={"onekeyword.com/seo-wizard"}
                quote={"Seo Wizard"}
                className="share-button"
              >
                <LinkedinIcon size={75} />
              </LinkedinShareButton>

              <TwitterShareButton
                url={"onekeyword.com/seo-wizard"}
                quote={"Seo Wizard"}
                className="share-button"
              >
                <TwitterIcon size={75} />
              </TwitterShareButton>

              <RedditShareButton
                url={"onekeyword.com/seo-wizard"}
                quote={"Seo Wizard"}
                className="share-button"
              >
                <RedditIcon size={75} />
              </RedditShareButton>
              <EmailShareButton
                url={"onekeyword.com/seo-wizard"}
                quote={"Seo Wizard"}
                className="share-button"
              >
                <EmailIcon size={75} />
              </EmailShareButton>
            </div>
            <button
              type="button"
              className="btn btn-link wizardPopupBody__buttomtext col-12 col-sm-10 col-md-8 align-self-center"
              onClick={this.onClose}
            >
              No, I want to be selfish
            </button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUpEmail: creds => dispatch(signUpEmail(creds))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
