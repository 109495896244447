import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import styled from 'styled-components';


import "./styles.scss";

const Hover = styled.p`
	color: #000;
	:hover {

        font-weight: bold;
    color: #1d48f5;
    cursor: pointer;
    text-decoration: underline;

	}
`




class BlogItem extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getAuthor = id => {
    let author = "";
    this.props.entryData.map(data => {
      if (id === data.sys.id) {
        author = data.fields.fullName;
      }
    });
    return author;
  };

  getImageBannerUrl = id => {
    let url = "";
    this.props.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  getAvatarImageUrl = id => {
    let url = "";
    let avatarId = "";
    this.props.entryData.map(data => {
      if (id === data.sys.id) {
        avatarId = data.fields.thumbnail.sys.id;
      }
    });
    this.props.assetData.map(data => {
      if (avatarId === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  onClickBlog = blog => {
    if (blog.fields.tag[0] === "usecase") {
      this.props.history.push({
        pathname: `/blog/${blog.fields.slug}`,
        state: {
          blog: blog,
          entryData: this.props.entryData,
          assetData: this.props.assetData
        }
      });
    }
  };

  render() {
    const { data } = this.props;
    return (
      <div
        onClick={() => this.onClickBlog(data)}
        className="blogItem col-12 col-sm-12 col-md-6 col-lg-4"
      >
        <div className="blogItem__in">
          <img
            src={this.getImageBannerUrl(data.fields.thumbnail.sys.id)}
            alt=""
            className="blogItem__img"
          />
          <div className="d-flex">
            <p className="blogItem__type">{data.fields.tag[0]}</p>
            <p className="blogItem__date">
              {moment(data.fields.Date).format("DD/MM/YY")}
            </p>
          </div>
          <Hover className="blogItem__title">{data.fields.title}</Hover>
          <div className="d-flex align-items-center ">
            <img
              //   src={require("../../assets/images/image.png")}
              src={this.getAvatarImageUrl(data.fields.author.sys.id)}
              alt=""
              className="blogItem__avatar"
            />
            <p className="blogItem__name">
              <span style={{ color: "#7e849d" }}>by{"  "} </span>
              {this.getAuthor(data.fields.author.sys.id)}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BlogItem);
