const initState = {
  authError: null
};

const emailReducer = (state = initState, action) => {
  switch (action.type) {
    case "EMAIL_ERROR":
      console.log("Email save error");
      return {
        ...state,
        authError: "Email failed"
      };

    case "EMAIL_SUCCESS":
      console.log("Email saved success");
      return {
        ...state,
        authError: null
      };

    default:
      return state;
  }
};

export default emailReducer;
