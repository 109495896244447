import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";

import { signIn } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";
// import NButton from "../ButtonContainer";
// import InputTextField from "../InputTextField";
// import ErrorMessage from "../ErrorMessage";

// import { loginRequest } from "../../actions/auth";
// import { LOGIN_SUCCESS, LOGIN_FAIL } from "../../constants/auth";

// import signinImg from "../../assets/landing/12.svg";
// import signinImg1 from "../../assets/landing/18.svg";
// import facebookIcon from "../../assets/SVG/facebook-logo.svg";
// import googleIcon from "../../assets/SVG/google-plus-symbol.svg";
import "./styles.scss";

const UserIcon = styled.img`
  width: 27px;
  height: 27;
  filter: invert(1);
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMsg: "",
      open: false
    };
  }

  componentDidMount() {
    this.setState({
      email: "",
      passowrd: ""
    });
  }
  componentWillReceiveProps(nextProps) {
    // const { auth } = nextProps;
    // const { status, error } = auth;
    // if (error) {
    //   this.setState({
    //     errorMsg: auth.error
    //   });
    //   if (status === LOGIN_FAIL) {
    //     this.setState({
    //       usernameError: error.username ? error.username.join() : "",
    //       emailError: error.email ? error.email.join() : ""
    //     });
    //   }
    // }
    // if (status === LOGIN_SUCCESS) {
    //   this.onClose();
    // }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onClose = () => {
    this.setState({
      email: "",
      passowrd: ""
    });
    this.props.onClose();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn({
      email: this.state.email,
      password: this.state.password
    });
  };

  render() {
    const { open, onClose, history } = this.props;
    const {
      username,
      usernameError,
      password,
      passwordError,
      errorMsg
    } = this.state;
    const valid = username && password;
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/" />;
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        maxWidth="lg"
        // style={{ borderRadius: "25px" }}
        className="dialog"
      >
        <DialogContent className="signup" style={{ padding: "unset" }}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="signup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>

          <div className="loginHeader">
            <img
              style={{ height: "290px", width: "40%" }}
              src={require("../../assets/SVG/logo1.svg")}
              alt=""
            />
            <p className="loginHeader__text">Sign In</p>
          </div>
          <div className="signupBody">
            <p className="signupBody__logintext" style={{ marginTop: "0px" }}>
              Email
            </p>
            <div className="input-group signupBody__inputForm">
              <input
                type="email"
                className=""
                id="email"
                placeholder=""
                onChange={this.handleChange}
              />
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/email.svg")}
                alt=""
              />
            </div>
            <p className="signupBody__logintext">Password</p>
            <div className="input-group signupBody__inputForm">
              <input
                type="password"
                className=""
                id="password"
                placeholder=""
                onChange={this.handleChange}
              />
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/lock.svg")}
                alt=""
              />
            </div>
            <div className="next row">
              <Button className="col-md-4" onClick={this.handleSubmit}>
                Log In
                <img
                  style={{ height: "14px", margin: "0 0 0 10px" }}
                  src={require("../../assets/SVG/right-arrow1.svg")}
                  alt=""
                />
              </Button>
              <div className="center red-text">
                {authError ? <p>{authError}</p> : null}
              </div>
            </div>
            {/* <p className="signupBody__buttomtext text-center">
              Already Have an Account? <span>Create My Account</span>
            </p> */}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
