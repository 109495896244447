import React, { Component } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import FaqItem from "../../components/FaqItem";
import {Helmet} from "react-helmet";
import "./faq.scss";

class index extends Component {
  state = {
    faqData: [],
    pageTitle : '',
    pageMeta : '',
    canonicalUrl: ''
  };

  componentDidMount() {

    axios
    .get(
      "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/3uXkFi5zWh8xh7pNThdjGn?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
    )
    .then(res => {
      this.setState({ pageMeta: res.data.fields.pageMeta }); 
      this.setState({ pageTitle: res.data.fields.pageTitle }); 
      this.setState({ canonicalUrl: res.data.fields.canonicalUrl }); 
    });
    
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=faq&order=fields.order"
      )
      .then(res => {
        this.setState({ faqData: res.data.items }); 
      });

  }
  render() {
    return (
      <div className="container faq ">
         <Helmet>
                <meta charSet="utf-8" />
                <title> {this.state.pageTitle} </title>
                <meta name="description" content={this.state.pageMeta} />
                <link rel="canonical" href={this.state.canonicalUrl} />



            </Helmet>

        <h1 className="text-center faq__title">
          Frequently Asked
          <br /> Questions
        </h1>
        {this.state.faqData.map((item, index) => (
          <FaqItem
            key={index}
            title={item.fields.question}
            text={item.fields.answer}
            state={this.props.location.state}
          />
        ))}
      </div>
    );
  }
}

export default index;
