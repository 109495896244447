import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { TextField, Button } from "@material-ui/core";
import { RightArrow, Refresh } from "../../components/Icon";
import { withStyles } from "@material-ui/core/styles";

import {Helmet} from "react-helmet";
import "./styles.scss";

let markdown = `
This block of Markdown contains <a href="https://en.wikipedia.org/wiki/HTML">HTML</a>, and will require the <code>html-parser</code> AST plugin to be loaded, in addition to setting the <code class="prop">escapeHtml</code> property to false.
`

const styles = theme => ({
  findButtonIcon: {
    height: "14px",
    margin: "0 0 0 10px"
  },
  findButton: {
    width: "100%",
    height: "100%",
    color: "#1d48f5",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  }
});



class SingleBlog extends Component {
  constructor() {
    super();
    this.state = {
      url: "",

    };

    this.onSubmit = this.onSubmit.bind(this);
    this.urlChange = this.urlChange.bind(this);
  }

  getAvatarImageUrl = id => {
    let url = "";
    let avatarId = "";
    console.log(this.props);
    this.props.location.state.entryData.map(data => {
      if (id === data.sys.id) {
        avatarId = data.fields.thumbnail.sys.id;
      }
    });
    this.props.location.state.assetData.map(data => {
      if (avatarId === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  getImageBannerUrl = id => {
    let url = "";
    this.props.location.state.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    console.log("banner url", url);
    return `url(http:${url})`;
  };

    
  getBlogPost()  {
    console.log("testttt");
    console.log(this.props.location.state.blog.fields.blogpost);
    return this.props.location.state.blog.fields.blogpost
  }


  getAuthor = id => {
    let author = "";
    this.props.location.state.entryData.map(data => {
      if (id === data.sys.id) {
        author = data.fields.fullName;
      }
    });
    return author;
  };

  getBlogPost() {
    return this.props.location.state.blog.fields.blogpost;
  }

  
  urlChange = e => {
    if (e.key === "Enter") {
      this.findButton.click();
    }
    this.setState({
      url: e.target.value
    });
  };

  onSubmit(e) {
    e.preventDefault();
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain name
      "\\s*|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!!pattern.test(this.state.url)) {
      this.props.history.push({
        pathname: "/analyze",
        url: this.state.url
      });
    }
  }



  render() {
    const { classes } = this.props;
    const {url } = this.state;
    const { blog } = this.props.location.state;
    console.log("blog", blog);
    return (
      <div className="single">

<Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={blog.fields.metaDescription} />
                <title>{blog.fields.title}</title>
                <link rel="canonical" href={"onekeyword.com/" + blog.fields.slug} />
                <script type="application/ld+json">{`
    {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "${blog.fields.title}",
      "wordcount": "${blog.fields.blogpost.length}",
      "url": "${"onekeyword.com/" + blog.fields.slug}",
      "datePublished": "${blog.fields.Date}",
      "description": "${blog.fields.meta}",
      "articleBody": "${blog.fields.blogpost}"

    }
  `}</script>
  
             

            </Helmet>
      
        <div
          className="single__title"
          style={{
            backgroundImage: this.getImageBannerUrl(
              blog.fields.imageBanner.sys.id
            )
            //"url(http://images.ctfassets.net/e9ct5l4tkfzk/3SfPnIH7FinAI0wcNljMWW/7f65138f4ab2b61803e1b6581b31ce3e/celebration.jpg)"
          }}
        >
          {/* this.getImageBannerUrl(
              blog.fields.imageBanner.sys.id
            ) */}
          <p className="single__title__date">
            {/* Technology - {moment(blog.fields.Date).format("DD/MM/YY")} */}
          </p>
          <p className="single__title__text">{blog.fields.title}</p>
        </div>
        <div className="single__info  ">
          <div className="container d-flex justify-content-between">
            <div className="single__info__author  ">
              <img
                //   src={require("../../assets/images/image.png")}
                src={this.getAvatarImageUrl(blog.fields.author.sys.id)}
                alt=""
                className="single__info__author__avatar"
              />
              <p className="single__info__author__name">
                <span style={{ color: "#7e849d" }}>Posted by:{"  "} </span>
                {this.getAuthor(blog.fields.author.sys.id)}
              </p>
            </div>
            <div className="single__info__author ">
              <img
                src={require("../../assets/SVG/calendar.svg")}
                //   src={this.getAvatarImageUrl(blog.fields.author.sys.id)}
                alt=""
                style={{ width: "20px", marginRight: "5px" }}
                //    className="single__info__author__avatar"
              />
              <p className="single__info__author__name">
                <span style={{ color: "#7e849d" }}>Published:{"  "} </span>
                {moment(blog.fields.Date).format("Do MMM YYYY")}
              </p>
            </div>
            {/* <div className="single__info__author  ">              
              <p className="single__info__author__name">
                <span style={{ color: "#7e849d" }}>Comments:{"  "} </span>8
              </p>
            </div> */}
          </div>
        </div>
        <div className="single__main container">
          <div className="row justify-content-center">
            <ReactMarkdown
              className="col-10 single__main__blogpost "
              source={this.getBlogPost()}
              escapeHtml={false}
            />
            <p className="col-12 text-center single__info__author__iconText">
              Share:
            </p>
            <div className="col-5 col-sm-4 col-md-4 col-lg-3 col-xl-3 justify-content-between d-flex">
              <div className="single__info__author__iconF">
                <img src={require("../../assets/SVG/facebook.svg")} alt="" />
              </div>
              <div className="single__info__author__iconT">
                <img src={require("../../assets/SVG/twitter.svg")} alt="" />
              </div>
              <div className="single__info__author__iconI">
                <img src={require("../../assets/SVG/insta.svg")} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="section6 center-align text-center"
          style={{ padding: "68px 0" }}
        >
          <div className="container">
            <div className="section6__underline" />
            <div className=" justify-content-between">
              <p className="section6__text text-left">
                The simplest way to grow
                <br /> your business on{" "}
                <span style={{ color: "#4285f4" }}>G</span>
                <span style={{ color: "#ea4335" }}>o</span>
                <span style={{ color: "#fbbc05" }}>o</span>
                <span style={{ color: "#4285f4" }}>g</span>
                <span style={{ color: "#34a853" }}>l</span>
                <span style={{ color: "#ea4335" }}>e</span>
              </p>
              <div className="input-group section6__findForm">
                <input
                  type="text"
                  className="section6__findForm__input"
                  placeholder="MyWebsite.com"
                  value={url}
                  onKeyPress={this.urlChange}
                  onChange={this.urlChange}
                />

                <span
                  className="input-group-addon section6__findForm__button"
                  ref={el => (this.findButton = el)}
                  onClick={this.onSubmit}
                >
                  <Button type="button" className={classes.findButton}>
                    <div className="section6__findForm__button__text">
                      Find Keywords
                    </div>
                    <RightArrow className={classes.findButtonIcon} />
                    <img
                      className={classes.findButtonIcon}
                      src={require("../../assets/SVG/right-arrow1.svg")}
                      alt=""
                    />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SingleBlog);