import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "./styles.scss";
import axios from "axios";
import ReactPlayer from "react-player";
import { NavLink, withRouter } from "react-router-dom";

import CompanySlider from "../../components/CompanySlider";

const UnderLine = styled.div`
  width: 70px;
  height: 4px;
  background-color: #425bff;
  margin: 0 auto;
`;

class Company extends Component {
  state = {
    pageTitle: "",
    pageMeta: "",
    canonicalUrl: "",
    beOfValue: "",
    Transparency: "",
    Accountability: "",
    FairPrice: "",
    givingBack: "",
  };

  getImageBannerUrl = id => {
    let url = "";
    this.props.location.state.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  componentDidMount() {
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/1QPcMoDZPqN93M0g3QnXD8?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({ pageMeta: res.data.fields.pageMeta });
        this.setState({ pageTitle: res.data.fields.pageTitle });
        this.setState({ canonicalUrl: res.data.fields.canonicalUrl });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/3it7HMFNVcXn3K8DJGwONK?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({
          beOfValue: res.data.fields.valueDescription,
        });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/4aDh5T60NgNBF6oskRiV5m?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({
          Transparency: res.data.fields.valueDescription,
        });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/1sq6WId5ldl8Z5EnC5gxNC?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({
          Accountability: res.data.fields.valueDescription,
        });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/3LySmZqtlCPOA9jQ4wvFVZ?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({
          FairPrice: res.data.fields.valueDescription,
        });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/JEfdFCWbouN09onaLQIKh?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({
          givingBack: res.data.fields.valueDescription,
        });
      });
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {this.state.pageTitle} </title>
          <meta name="description" content={this.state.pageMeta} />
          <link rel="canonical" href={this.state.canonicalUrl} />
        </Helmet>

        <div className="mission">
          <div className="container row">
            <div className="col-12 col-xl-6">
              <div className="mission__underline " />
              <p className="mission__title">Our Mission</p>
              <p className="mission__text">
                Our mission is to provide the highest quality and best
                researched search engine optimization (SEO) services to our
                clients with complete transparency, at a fair price.
              </p>
            </div>
            <div className="col-12 col-xl-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=W-oQ58CAYFU"
                // url={this.findVideoUrl(video)}
                muted
                loop
                className="mission__video"
              />
            </div>
          </div>
        </div>
        <div className="founder">
          <div className="container row " style={{ margin: "0 auto" }}>
            <div
              className="founder__personalInfo col-12 col-sm-12 col-md-5 center-align text-center"
              style={{ paddingRight: "45px" }}
            >
              <img
                style={{
                  width: "100%",
                  margin: "0 0 25px 0px"
                }}
                src={require("../../assets/images/image.png")}
                alt=""
              />
              <div className="d-flex justify-content-end align-items-center">
                <img
                  style={{ height: "24px", margin: "0 5px 0 0" }}
                  src={require("../../assets/images/linkedinIcon.png")}
                  alt=""
                />
                <a
                  href={
                    "https://www.linkedin.com/in/eugiene-jonathan-pisarevsky"
                  }
                  className="founder__connectButton"
                >
                  Let's Connect
                </a>
              </div>
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginTop: "45px" }}
              >
                <img
                  style={{
                    height: "60px",
                    width: "136.8px",
                    padding: "10px",
                    borderRight: "solid 1px #e6e7f5"
                  }}
                  src={require("../../assets/images/santa-monica.png")}
                  alt=""
                />
                <div style={{ padding: "10px" }}>
                  <p className="founder__detailInfo">
                    Endorsed by President of Santa Monica Chamber of Commerce.
                    <br />
                    <span className="founder__detailMore">   <NavLink  style={{  color: '#7e849d' }} to="/testimonials"> Learn More </NavLink></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="founder__personalText col-12 col-sm-12 col-md-7  text-left flex-column d-flex justify-content-end">
              <UnderLine style={{ margin: "unset" }} />
              <p className="text-left founder__meetText">Meet Our Founder</p>
              <p className="text-left founder__title">
                EUGIENE <span>JONATHAN</span> <br />
                PISAREVSKY
              </p>
              <p className="founder__text">
              For me marketing has always been about shining a light on something that has a positive impact on the world. I choose to work with businesses that I personally believe in. As my colleagues have put it, I was born with a 6th sense for the digital world and during the past 15 years I have immersed myself in every sphere of digital marketing to gain a deeper understanding of what it takes to truly grow a brand. Today, I love helping businesses become more successful through maximizing their visibility in a way that captures the attention of both the intellect and, more importantly, the heart.
                <br />
                <br />
                This is the third article of a three-part series. I’m
                illustrating the marketing challenges of PrescottWeddings.com, a
                small business. If you don’t remember anything else about
                marketing, remember this: Frequency is king. The more often you
                can get your name in front of your potential and current
                customers, the more likely you will make a sale. Depending on
                what study you look at, people need to see your message anywhere
                from three to 27 times before they act upon it.
                <br />
                <br />
                And, if you want to brand your business, then you need to get it
                in front of your customers as often as possible.
              </p>
              <p className="founder__bigText">
                “To be of service is a blessing in itself.”
              </p>
              <img
                style={{
                  height: "43px",
                  width: "157px",
                  marginTop: "30px"
                }}
                src={require("../../assets/images/sign.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container values">
          <UnderLine style={{ margin: "0 auto" }} />
          <p className="text-center values__title">Our Values</p>
          <div className="row value">
            <p className="value__title col-12 col-sm-12 col-md-4 center-align text-left">
              Be of Value
            </p>
            <p className="value__text col-12 col-sm-12 col-md-8 center-align text-left">
              {this.state.beOfValue}
            </p>
          </div>
          <div className="row value">
            <p className="value__title col-12 col-sm-12 col-md-4 center-align text-left">
              Transparency
            </p>
            <p className="value__text col-12 col-sm-12 col-md-8 center-align text-left">
            {this.state.Transparency}

            </p>
          </div>
          <div className="row value">
            <p className="value__title col-12 col-sm-12 col-md-4 center-align text-left">
              Accountability
            </p>
            <p className="value__text col-12 col-sm-12 col-md-8 center-align text-left">
            {this.state.Accountability}

            </p>
          </div>
          <div className="row value">
            <p className="value__title col-12 col-sm-12 col-md-4 center-align text-left">
              Fair Price
            </p>
            <p className="value__text col-12 col-sm-12 col-md-8 center-align text-left">
            {this.state.FairPrice}

            </p>
          </div>
          {/* <div className="row value">
            <p className="value__title col-12 col-sm-12 col-md-4 center-align text-left">
              Giving Back
            </p>
            <p className="value__text col-12 col-sm-12 col-md-8 center-align text-left">
            {this.state.givingBack}

            </p>
          </div> */}

        </div>
        <div className="companies">
          <div className="container">
            <UnderLine style={{ margin: "0 auto" }} />
            <p className="text-center companies__title">
              You're in Good Company
            </p>
            <p className="text-center companies__text">
              With over 15 years of Digital Marketing and SEO experience, our
              founder has personally work with some of the most iconic brands in
              the world. Here are a few:
            </p>
          </div>
        </div>
        <div className="companySldier">
          <CompanySlider />
        </div>
      </div>
    );
  }
}

export default Company;
