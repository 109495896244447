import React, { Component } from "react";

import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";
import "./styles.scss";

class FaqItem extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  clickButton = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { title, text, state } = this.props;
    const { open } = this.state;
    let isOpen = false; //open;
    if (state) {
      state.question === title && !open ? (isOpen = true) : (isOpen = false);
      console.log("state", isOpen);
    }
    return (
      <div className="faqItem" onClick={this.clickButton}>
        <div className="faqItem__body">
          <p className="faqItem__title">{title}</p>
          {state
            ? state.question === title
              ? isOpen && <p className="faqItem__text">{text}</p>
              : (isOpen || open) && <p className="faqItem__text">{text}</p>
            : open && <p className="faqItem__text">{text}</p>}
        </div>
        {/* <IconButton
          style={{
            padding: "unset"
          }}
          className="signup__close"
          onClick={this.clickButton}
        > */}
        {isOpen ? (
          <img
            style={{ height: "24px" }}
            src={require("../../assets/SVG/minus.svg")}
            alt=""
          />
        ) : (
          <img
            style={{ height: "24px" }}
            src={require("../../assets/SVG/plus.svg")}
            alt=""
          />
        )}
        {/* </IconButton> */}
      </div>
    );
  }
}

export default FaqItem;
