import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import styled from 'styled-components';

import "./styles.scss";




class BlogMain extends Component {
  constructor() {
    super();
    this.state = {
      blogMain: false
    };
  }


  componentDidMount() {
    this.props.blogData.map(blog => {
      if (blog.fields.isMain) {
        this.setState({
          blogMain: blog
        });
      }
    });
  }

  getAuthor = id => {
    let author = "";
    this.props.entryData.map(data => {
      if (id === data.sys.id) {
        author = data.fields.fullName;
      }
    });
    return author;
  };

  getImageBannerUrl = id => {
    let url = "";
    this.props.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  getAvatarImageUrl = id => {
    let url = "";
    let avatarId = "";
    this.props.entryData.map(data => {
      if (id === data.sys.id) {
        avatarId = data.fields.thumbnail.sys.id;
      }
    });
    this.props.assetData.map(data => {
      if (avatarId === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  onClickBlog = blog => {
    if (blog.fields.tag[0] === "usecase") {
      this.props.history.push({
        pathname: `/blog/${blog.fields.slug}`,
        state: {
          blog: blog,
          entryData: this.props.entryData,
          assetData: this.props.assetData
        }
      });
    }
  };

  render() {
    const { blogMain } = this.state;
    console.log("blogMain", blogMain);

    if (!blogMain) return <div />;
    return (
      <div className="blogMain col-12 col-sm-12 col-md-12 col-lg-12">
        {blogMain && (
          <div className="blogMain__in d-block d-sm-block d-md-flex">
            <img
              src={this.getImageBannerUrl(blogMain.fields.imageBanner.sys.id)}
              alt=""
              className="blogMain__img"
            />
            <div className="blogMain__info">
              <div className="d-flex">
                <p className="blogMain__type">{blogMain.fields.tag[0]}</p>
                <p className="blogMain__date">
                  {moment(blogMain.fields.Date).format("DD/MM/YY")}
                </p>
              </div>
              <p className="blogMain__title">{blogMain.fields.title}</p>
              <p className="blogMain__summary">{blogMain.fields.summary}</p>
              <div className="d-flex align-items-center ">
                <img
                  //   src={require("../../assets/images/image.png")}
                  src={this.getAvatarImageUrl(blogMain.fields.author.sys.id)}
                  alt=""
                  className="blogMain__avatar"
                />
                <p className="blogMain__name">
                  <span style={{ color: "#7e849d" }}>by{"  "} </span>
                  {this.getAuthor(blogMain.fields.author.sys.id)}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(BlogMain);
