import React, { Component } from "react";
import { Button, Checkbox } from "@material-ui/core";
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  CardElement
} from "react-stripe-elements";
import ReactTooltip from 'react-tooltip'
import { withRouter } from "react-router-dom";
import { userPaidSet } from "../../store/actions/authActions";
import disableBrowserBackButton from 'disable-browser-back-navigation';

import axios from "axios";
import "./styles.scss";

const handleBlur = () => {
  // console.log("[blur]");
};
const handleChange = change => {
  // console.log("[change]", change);
};
const handleClick = () => {
  // console.log("[click]");
};
const handleFocus = () => {
  // console.log("[focus]");
};
const handleReady = () => {
  // console.log("[ready]");
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        },
        padding
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      complete: false,
      inputName: "",
      country: "",
      phone: "",
      city: "",
      zip: "",
      //   cardid: "",
      check: false
    };
    this.submit = this.submit.bind(this);
    disableBrowserBackButton();

  }


  
  updateInput = evt => {
    // console.log("event", evt);
    this.setState({
      [evt.target.id]: evt.target.value
    });
  };

  async submit(token) {
    // let { token } = await this.props.stripe.createToken({ name: name });
    //  console.log("tokenInfo", name, token);
    this.setState({
      //  cardid: token.card.id,
      token: token.id
    });
    let response = await fetch(
      "https://us-central1-onekeyword-a6a3e.cloudfunctions.net/stripePayment",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          token,
          charge: {
            amount: 100,
            currency: "USD"
          }
        })
      }
    );

    if (response.ok) {
      console.log("Purchase Complete!", response);
    }
    response
      .json()
      .then(res => {
        console.log(JSON.parse(res.body), "resss");
        this.setState({
          complete: true
        });
        this.props.selectedKeywords.map(keyword => {
          const baseUrl =
            "https://xbfijgsq5k.execute-api.us-east-1.amazonaws.com/staging?client_id=" +
            this.props.auth.uid +
            "&keyword=" +
            keyword.key +
            "&domain=" +
            keyword.exact_domain;
          axios.get(baseUrl).then(res => {
            console.log("keyword sent backend", res);
          });
        });
        //   console.log("stripe", this.state.cardid, this.props.auth.uid);
        this.props.userPaidSet({
          //   cardid: this.state.cardid,
          token: this.state.token,
          paid: true,
          uid: this.props.auth.uid,
          username: this.state.inputName,
          phone: this.state.phone,
          country: this.state.country,
          city: this.state.city,
          zip: this.state.zip
        });
      })
      .catch(error => {
        console.log("err", error);   
         });

  }

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.stripe
      .createToken({
        type: "card",
        name: this.state.inputName,
        phone: this.state.phone,
        address_country: this.state.country,
        address_zip: this.state.zip,
        address_city: this.state.city.split(" ")[0],
        address_state: this.state.city.split(" ")[1]
      })
      .then(payload => {
        if (payload.token) {
          this.submit(payload.token);
        } else if (payload.error) {
          console.log("CARD ERROR");
          console.log("error", payload.error);
        }
      });
  };

  onChangeCheck = () => {
    this.setState({ check: !this.state.check });
  };

  render() {
    if (this.state.complete) return <h1>Purchase Complete</h1>;

    return (
      <form
        className="checkout row"
        onSubmit={this.handleSubmit}
        //       onSubmit={() => this.submit(this.state.inputName)}
      >
        <div className="checkout__form col-12 col-sm-12 col-md-6">
          <p className="checkout__form__title">01. AccountDetails</p>

          <label>
            First & Last Name
            <div className="input-group inputItem">
              <input
                type="text"
                id="inputName"
                className="form-control inputItem__input"
                value={this.state.inputName}
                onChange={this.updateInput}
                {...createOptions(this.props.fontSize)}
                required
              />
              <img
                className="inputItem__iconimg"
                src={require("../../assets/SVG/user.svg")}
                alt=""
              />
            </div>
          </label>
          <label>
            Country
            <div className="input-group inputItem">
              <input
                type="text"
                id="country"
                className="form-control inputItem__input"
                value={this.state.country}
                onChange={this.updateInput}
                {...createOptions(this.props.fontSize)}
                required
              />
              <img
                className="inputItem__iconimg"
                src={require("../../assets/SVG/country.svg")}
                alt=""
              />
            </div>
          </label>
          <label>
            Phone (optional)
            <div className="input-group inputItem">
              <input
                type="number"
                id="phone"
                className="form-control inputItem__input"
                value={this.state.phone}
                onChange={this.updateInput}
                {...createOptions(this.props.fontSize)}
              />
              <img
                className="inputItem__iconimg"
                // src={require("../../assets/SVG/country.svg")}
                alt=""
              />
            </div>
          </label>
          <div className=" d-flex justify-content-between">
            <label>
              <span>City/State</span>
              <input
                style={{ fontSize: "16px" }}
                type="text"
                id="city"
                value={this.state.city}
                onChange={this.updateInput}
                size="20"
                data-stripe="address_country"
                required
              />
            </label>
            <label>
              <span>ZIP/Postal</span>
              <input
                style={{ fontSize: "16px" }}
                type="text"
                id="zip"
                value={this.state.zip}
                onChange={this.updateInput}
                size="20"
                data-stripe="address_zip"
                required
              />
            </label>
          </div>
          <p className="checkout__form__title">02. Payment Details</p>
          <div className=" d-flex justify-content-between align-items-center">
            <label>
              <span>Select payment method</span>
            </label>
            <img
              className=""
              style={{ height: "30px", width: "210px" }}
              src={require("../../assets/SVG/cards.png")}
              alt=""
            />
          </div>
          <div className=" d-flex justify-content-between align-items-center">
            <div className="input-group inputItem" style={{ width: "49%" }}>
              <span className="inputItem__img">
                <Checkbox disabled checked={false} />
              </span>
              <input
                style={{ color: "#7e849d" }}
                type="text"
                className="form-control inputItem__input"
                value="Paypal"
                disabled
              />
            </div>
            <div
              className="input-group inputItem"
              style={{
                width: "47%",
                border: "solid 1px #1d48f5",
                backgroundColor: "#ffffff"
              }}
            >
              <span className="inputItem__img">
                <Checkbox style={{ color: "#1d48f5" }} checked={true} />
              </span>
              <input
                type="text"
                className="form-control inputItem__input"
                value="Credit Card"
                disabled
              />
            </div>
          </div>
          <label>
            Card number
            <CardNumberElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
            />
          </label>
          <label>
            Expiration date
            <CardExpiryElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
            />
          </label>
          <label>
            CVC
            <CardCVCElement {...createOptions(this.props.fontSize)} />
          </label>
        </div>

        <div className="checkout__description col-12 col-sm-12 col-md-6">
          <label>OneKeyword Professional Subscription</label>
          <p>
            Keywords Chosen:{" "}
            <span>
              {this.props.selectedKeywords &&
                this.props.selectedKeywords.length}
            </span>{" "}
            &nbsp;&nbsp;&nbsp;Billed Now:&nbsp;
            <span style={{ color: "#1d48f5" }}>
              <b>
                $
                {this.props.selectedKeywords &&
                  199 * this.props.selectedKeywords.length}
              </b>
            </span>
          </p>
          <div className="checkout__description__underline" />
          <p className="checkout__description__text">
            You’ll be charged{" "}
            <span style={{ color: "#415089" }}>
              $
              {this.props.selectedKeywords &&
                199 * this.props.selectedKeywords.length}
              &nbsp;
            </span>
            every 30 days. Once you are happy with your ranking you can switch
            to another keyword. You can also add new keywords or remove existing
            keywords at any time. If we do not improve your rankings in 60 days
            you can request a complete refund as part of our{" "}
            
          
            <ReactTooltip place="bottom" type="" effect="solid"   className="tooltipTextInfo"/>

            <span data-tip="Try One Keyword risk free for up to 2 months (60 days) and if we don’t increase the ranking of your chosen keyword(s) we will issue a full refund. Enjoy peace of mind and let us go to work growing your reach on Google. Please see FAQ for details regarding sites that require on-site optimization." style={{ color: "#1d48f5" }}>higher rankings guarantee.</span>{" "}
            No questions asked. You can also cancel at any time to stop your
            subscription.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 40px"
            }}
          >
            <Checkbox
              checked={this.state.check}
              onChange={this.onChangeCheck}
            />
            <p
              style={{
                fontSize: "18px",
                marginBottom: "0px",
                paddingLeft: "0px"
              }}
            >
              I agree to{" "}
              <span ><Link style={{ color: "#1d48f5" }} to="" target="_blank" to="/terms" >OneKeyword's Terms</Link>
</span>
            </p>
          </div>
          <div style={{ padding: "0 40px" }}>
            <Button
              style={
                !this.state.check
                  ? {
                      backgroundImage:
                        "linear-gradient(to right, rgba(244,80,98, 0.5), rgba(244,130,147,0.2))"
                    }
                  : null
              }
              disabled={!this.state.check}
              type="submit"
            >
              Order Now
            </Button>
          </div>
          {/* <Button onClick={() => this.submit(this.state.inputName)}>
            Pay
          </Button> */}
          <div className="checkout__description__underline" />
          <div className="checkout__description__options">
            <p className="checkout__description__options__title">
              What’s included in your OneKeyword Professional Subscription
            </p>
            <div className="checkout__description__options__subtitle">
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/check.svg")}
                alt=""
              />
              <p className="checkout__description__options__text">
                Real Time Rank Tracking
              </p>
            </div>
            <div className="checkout__description__options__subtitle">
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/check.svg")}
                alt=""
              />
              <p className="checkout__description__options__text">
                Permanent Ranking Increases
              </p>
            </div>
            <div className="checkout__description__options__subtitle">
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/check.svg")}
                alt=""
              />
              <p className="checkout__description__options__text">
                Strategic Link Building Every Month
              </p>
            </div>
            <div className="checkout__description__options__subtitle">
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/check.svg")}
                alt=""
              />
              <p className="checkout__description__options__text">
                Proprietary Mix of Social and Blog Links
              </p>
            </div>
            <div className="checkout__description__options__subtitle">
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/check.svg")}
                alt=""
              />
              <p className="checkout__description__options__text">
                Priority Support
              </p>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const StripeCheckoutForm = injectStripe(withRouter(CheckoutForm));

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userPaidSet: creds => dispatch(userPaidSet(creds))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeCheckoutForm);
