import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";

import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Login from "../../../components/Login";
import Trial from "../../../components/Trial";
import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = theme => ({
  signin: {
    width: "120px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 ",
    textTransform: "none",
    backgroundImage: "linear-gradient(to right, #3e1dde, #34a5ec)",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)"
  },
  freetrial: {
    width: "120px",
    //  backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    boxShadow: "0 10px 20px 0 rgba(0,0,0,0.15)",
    backgroundImage: "linear-gradient(to right, #f45062, #f48293)",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 px 0 20px",
    textTransform: "none"
  }
});

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openLogin: false,
      trial: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.location.pathname !== nextProps.location.pathname &&
      !this.props.auth.uid
    ) {
      this.onClose();
    }
  }
  openLogin = () => {
    this.setState({
      openLogin: true,
      open: false
    });
  };

  openTrial = () => {
    this.setState({
      trial: true,
      open: false
    });
  };

  onClose = () => {
    this.setState({
      open: false,
      openLogin: false,
      trial: false
    });
    this.props.onClose();
  };

  render() {
    const { classes, open, onClose, history } = this.props;
    const {
      username,
      usernameError,
      password,
      passwordError,
      errorMsg
    } = this.state;
    const valid = username && password;
    const { authError, auth } = this.props;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.onClose}
        TransitionComponent={Transition}
        //maxWidth="lg"
        // style={{ borderRadius: "25px" }}
        className="menu"
      >
        <Login open={this.state.openLogin} onClose={this.onClose} />
        <Trial open={this.state.trial} onClose={this.onClose} />
        <DialogContent className="menu__body" style={{ padding: "unset" }}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="signup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>
          <div className="">
            {/* <div className={"p-2 menuItemD"}>
              <NavLink to="/testimonials">Testimonials</NavLink>
            </div> */}
            {/* <div className={"p-2 menuItemD"}>
              <NavLink to="/company">Abous us</NavLink>
            </div> */}
            <div className={"p-2 menuItemD"}>
              <NavLink to="/faq">FAQ</NavLink>
            </div>
            <div className={"p-2 menuItemD"}>
              <NavLink to="/topic-finder">Blogger's Best Friend</NavLink>
            </div>
            <div className={"p-2 menuItemD"}>
              <NavLink to="/seo-wizard">One-Page SEO Wizard</NavLink>
            </div>

            <div className={"p-2 menuItemD"}>
              <Button className={classes.signin} onClick={this.openLogin}>
                Sign In
              </Button>
            </div>
            <div className={"p-2 menuItemD"}>
              <Button className={classes.freetrial} onClick={this.openTrial}>
                Risk Free Trial
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles)(Menu));
