import React, { Component } from "react";
import { compose } from "redux";
import ReactTooltip from "react-tooltip";

import axios from "axios";
import { connect } from "react-redux";
import styled from "styled-components";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";

import BlogSlider from "../../components/BlogSlider";
import CaseSlider from "../../components/CaseSlider";
import Industries from "../../components/Industries";
import { RightArrow, Refresh } from "../../components/Icon";
import "./dashboard.scss";

const styles = theme => ({
  findButtonIcon: {
    height: "14px",
    margin: "0 0 0 10px"
  },
  findButton: {
    width: "100%",
    height: "100%",
    color: "#1d48f5",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreBusinessButtonIcon: {
    height: "20px",
    margin: "0 10px 0 0px",
    color: "#1d48f5"
  },
  learnMoreBusinessButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& svg": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& svg": {
        display: "block"
      },
      "& img": {
        display: "none"
      }
    }
  },
  caseButton: {
    width: "242px",
    height: "60px",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    color: "#1d48f5",
    fontSize: "16px",
    //   marginTop: "-50px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  }
});

const UnderLine = styled.div`
  width: 70px;
  height: 4px;
  background-color: #425bff;
  margin: 0 auto;
`;

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      loadmore: false,
      firstData: [],
      stepsData: [],
      whyData: [],
      whenData: [],
      pageTitle: "",
      pageMeta: "",
      canonicalUrl: "",
      firstText: "",
      topDescription: "",
      underSearch: "",
      button1: ""
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.urlChange = this.urlChange.bind(this);
    this.routeChange = this.routeChange.bind(this);
    this.onClickLearnmore1 = this.onClickLearnmore1.bind(this);
    this.onClickLearnmore2 = this.onClickLearnmore2.bind(this);
    this.onClickLearnmore3 = this.onClickLearnmore3.bind(this);
  }

  componentDidMount() {
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/2hgBlpyqtZ0c11mX7yXzIU?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({ firstText: res.data.fields.topTitle });
        this.setState({ topDescription: res.data.fields.topDescription });
        this.setState({ underSearch: res.data.fields.underSearch });
        this.setState({ button1: res.data.fields.button1 });
      });

    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/3CZq0z5hVNE4vkzXLmsFTv?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
      )
      .then(res => {
        this.setState({ pageMeta: res.data.fields.pageMeta });
        this.setState({ pageTitle: res.data.fields.pageTitle });
        this.setState({ canonicalUrl: res.data.fields.canonicalUrl });
      });

    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=how"
      )
      .then(res => {
        this.setState({ firstData: res.data.items });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=steps"
      )
      .then(res => {
        this.setState({ stepsData: res.data.items });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=why"
      )
      .then(res => {
        this.setState({ whyData: res.data.items });
      });
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=when"
      )
      .then(res => {
        this.setState({ whenData: res.data.items });
      });
  }

  componentDidUpdate(prevProps) {
    //  console.log("123123", this.props.location);
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  urlChange = e => {
    if (e.key === "Enter") {
      this.findButton.click();
    }
    this.setState({
      url: e.target.value
    });
  };

  onSubmit(e) {
    e.preventDefault();
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain name
      "\\s*|" +
      "((\\d{1,3}\\.){  3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!!pattern.test(this.state.url)) {
      this.props.history.push({
        pathname: "/analyze",
        url: this.state.url
      });
    }
  }

  onClickLoadmore = () => {
    this.setState({
      loadmore: !this.state.loadmore
    });
  };

  onClickLearnmore1(q) {
    this.props.history.push({
      pathname: "/faq",
      state: {
        question: "How does this work and what exactly do you do every month?"
      }
    });
  }
  onClickLearnmore2(q) {
    this.props.history.push({
      pathname: "/faq",
      state: {
        question: "How does ranking higher in Google search help my business?"
      }
    });
  }
  onClickLearnmore3(q) {
    this.props.history.push({
      pathname: "/faq",
      state: {
        question: "When will I see results?"
      }
    });
  }

  routeChange() {
    let path = `cases`;
    this.props.history.push(path);
  }

  render() {
    const { projects, auth, notifications, classes } = this.props;
    // if (!auth.uid) return <Redirect to="/signin" />;
    const {
      url,
      loadmore,
      firstData,
      stepsData,
      whyData,
      whenData
    } = this.state;
    if (auth.uid) return <Redirect to="/home" />;

    return (
      <div className="dashboard">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {this.state.pageTitle} </title>
          <meta name="description" content={this.state.pageMeta} />
          <meta
            name="google-site-verification"
            content="kVRQ__qrScm_x_nGE1lV08cgaLStfsrJ0W6kAd2TXfg"
          />
          <link rel="canonical" href={this.state.canonicalUrl} />
        </Helmet>

        <div className="dashboardHome">
          <div className="container">
            <div className="section1 justify-content-md-center position-relative">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 align-self-center">
                <p>
                  Rank any keyword at the top of Google for{" "}
                  <span>$199 / month</span>
                </p>

                <p>{this.state.topDescription} </p>
                <div className="input-group section6__findForm">
                  <input
                    type="text"
                    className="section6__findForm__input"
                    //   placeholder="Your website"
                    placeholder="MyWebsite.com"
                    value={url}
                    onKeyPress={this.urlChange}
                    onChange={this.urlChange}
                  />

                  <span
                    className="input-group-addon section6__findForm__button"
                    ref={el => (this.findButton = el)}
                    onClick={this.onSubmit}
                  >
                    <Button type="button" className={classes.findButton}>
                      <div className="section6__findForm__button__text">
                        Find Keywords
                      </div>
                      <RightArrow className={classes.findButtonIcon} />
                      <img
                        className={classes.findButtonIcon}
                        src={require("../../assets/SVG/right-arrow1.svg")}
                        alt=""
                      />
                    </Button>
                  </span>
                </div>

                <p
                  className="textRank"
                  data-tip="Try One Keyword risk free for up to 2 months (60 days) and if we don’t increase the ranking of your chosen keyword(s) we will issue a full refund. Enjoy peace of mind and let us go to work growing your reach on Google. Please see FAQ for details regarding sites that require on-site optimization."
                >
                  {this.state.underSearch}
                </p>
              </div>
              {/* <div
                //  className="position-absolute"
                className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 d-none d-xl-block "
                style={{ position: "absolute", top: "180px", right: "0" }}
              >
                <img
                  style={{ width: "178%" }}
                  src={require("../../assets/SVG/image.svg")}
                  alt=""
                  className="responsive-img d-flex justify-content-end"
                />
                <img
                  src={require("../../assets/SVG/mobile.svg")}
                  alt=""
                  className="position-absolute "
                  style={{ margin: "-71% 0 0 117%", width: "57%" }}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row section2">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
              <img
                src={require("../../assets/SVG/undraw-visual-data-b-1-wx.svg")}
                alt=""
                className="section2__img"
              />
              <p className="section2__title">
                {firstData.length !== 0 && firstData[2].fields.howQuestion}
              </p>
              <p className="section2__text">
                {firstData.length !== 0 && firstData[2].fields.description}
              </p>
              <div
                className="section2__more"
                onClick={() =>
                  this.onClickLearnmore2(
                    firstData.length !== 0 && firstData[2].fields.description
                  )
                }
              >
                <p>Learn more</p>
                <img src={require("../../assets/SVG/right-arrow.svg")} />
              </div>
            </div>
            <div className="ol-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <img
                src={require("../../assets/SVG/undraw-timeline-9-u-4-u.svg")}
                alt=""
                className="section2__img"
              />
              <p className="section2__title">
                {firstData.length !== 0 && firstData[0].fields.howQuestion}
              </p>
              <p className="section2__text">
                {firstData.length !== 0 && firstData[0].fields.description}
              </p>
              <div
                className="section2__more"
                onClick={() =>
                  this.onClickLearnmore1(
                    firstData.length !== 0 && firstData[0].fields.description
                  )
                }
              >
                <p>Learn more</p>
                <img src={require("../../assets/SVG/right-arrow.svg")} />
              </div>
            </div>
            <div className="ol-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <img
                src={require("../../assets/SVG/undraw-time-management-30-iu.svg")}
                alt=""
                className="section2__img"
              />
              <p className="section2__title">
                {firstData.length !== 0 && firstData[1].fields.howQuestion}
              </p>
              <p className="section2__text">
                {firstData.length !== 0 && firstData[1].fields.description}
              </p>
              <div
                className="section2__more"
                onClick={() =>
                  this.onClickLearnmore3(
                    firstData.length !== 0 && firstData[1].fields.description
                  )
                }
              >
                <p>Learn more</p>
                <img src={require("../../assets/SVG/right-arrow.svg")} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="section3 center-align text-center"
          style={
            loadmore
              ? { height: "max-content" }
              : { height: "1000px", overflowY: "hidden" }
          }
        >
          <UnderLine />
          <p className="section3__title">
            Trusted by Successful Business Owners and Entrepreneurs Across
            Dozens of Industries
          </p>
          <Industries />
          {!loadmore && (
            <div className="section3__hide">
              <Button
                type="button"
                className={classes.learnMoreBusinessButton}
                onClick={this.onClickLoadmore}
              >
                <img
                  className={classes.learnMoreBusinessButtonIcon}
                  src={require("../../assets/SVG/refresh.svg")}
                  alt=""
                />
                <Refresh className={classes.learnMoreBusinessButtonIcon} />
                View More
              </Button>
            </div>
          )}
        </div>

        <div className="section4 text-center">
          <img
            className="section4__img2"
            src={require("../../assets/avatars/oval1.png")}
          />
          <img
            className="section4__img3"
            src={require("../../assets/avatars/oval2.png")}
          />
          <img
            className="section4__img4"
            src={require("../../assets/avatars/oval4.png")}
          />
          <img
            className="section4__img5"
            src={require("../../assets/avatars/oval5.png")}
          />
          <img
            className="section4__img6"
            src={require("../../assets/avatars/oval3.png")}
          />
          <UnderLine />
          <p className="section4__title">What our Clients Say</p>
          <p className="section4__point">“</p>
          <BlogSlider />
        </div>

        <div className="section5 text-center">
          <UnderLine />
          <p className="text-center section5__title">
            Starting is as Easy as 1-2-3
          </p>
          <div className="row container section5__body">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 center-align text-center">
              <img
                src={require("../../assets/SVG/01-seeds.png")}
                alt=""
                className="section5__img"
              />
              <div className="section5__hidediv">
                <p className="section5__number">01</p>
                <p className="section5__text">
                  {stepsData.length !== 0 &&
                    stepsData[1].fields.stepDescription}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 center-align text-center">
              <img
                src={require("../../assets/SVG/02-tree.png")}
                alt=""
                className="section5__img"
              />
              <div className="section5__hidediv">
                <p className="section5__number">02</p>
                <p className="section5__text">
                  {stepsData.length !== 0 &&
                    stepsData[2].fields.stepDescription}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 center-align text-center">
              <img
                src={require("../../assets/SVG/03-tree.png")}
                alt=""
                className="section5__img"
              />
              <div className="section5__hidediv">
                <p className="section5__number">03</p>
                <p className="section5__text">
                  {stepsData.length !== 0 &&
                    stepsData[0].fields.stepDescription}
                </p>
              </div>
            </div>
          </div>
          <div
            className="row container section5__body section5__body__order"
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "relative"
            }}
          >
            <div className="section5__body__line col s12 m8 " />
            <div className="col s12 m4 center-align text-center">
              <p className="section5__number">01</p>
            </div>
            <div className="col s12 m4 center-align text-center">
              <p className="section5__number">02</p>
            </div>
            <div className="col s12 m4 center-align text-center">
              <p className="section5__number">03</p>
            </div>
          </div>
          <div className="row container section5__body section5__body__text">
            <div className="col s12 m4 center-align text-center">
              <p className="section5__text">
                {stepsData.length !== 0 && stepsData[1].fields.stepDescription}
              </p>
            </div>
            <div className="col s12 m4 center-align text-center">
              <p className="section5__text">
                {stepsData.length !== 0 && stepsData[2].fields.stepDescription}
              </p>
            </div>
            <div className="col s12 m4 center-align text-center">
              <p className="section5__text">
                {stepsData.length !== 0 && stepsData[0].fields.stepDescription}
              </p>
            </div>
          </div>
        </div>
        <div className="section6 center-align text-center">
          <div className="container">
            <div className="section6__underline" />
            <div className="d-xl-flex justify-content-between">
              <p className="section6__text text-left">
                The simplest way to grow
                <br /> your business on{" "}
                <span style={{ color: "#4285f4" }}>G</span>
                <span style={{ color: "#ea4335" }}>o</span>
                <span style={{ color: "#fbbc05" }}>o</span>
                <span style={{ color: "#4285f4" }}>g</span>
                <span style={{ color: "#34a853" }}>l</span>
                <span style={{ color: "#ea4335" }}>e</span>
              </p>
              <div className="input-group section6__findForm">
                <input
                  type="text"
                  className="section6__findForm__input"
                  placeholder="MyWebsite.com"
                  value={url}
                  onKeyPress={this.urlChange}
                  onChange={this.urlChange}
                />

                <span
                  className="input-group-addon section6__findForm__button"
                  ref={el => (this.findButton = el)}
                  onClick={this.onSubmit}
                >
                  <Button type="button" className={classes.findButton}>
                    <div className="section6__findForm__button__text">
                      Find Keywords
                    </div>
                    <RightArrow className={classes.findButtonIcon} />
                    <img
                      className={classes.findButtonIcon}
                      src={require("../../assets/SVG/right-arrow1.svg")}
                      alt=""
                    />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className=" section7 container ">
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-6 center-align text-center d-none d-sm-none d-md-flex">
              <img
                style={{ width: "100%", margin: "15px 0 5px -15px" }}
                src={require("../../assets/SVG/why-it-works.svg")}
                alt=""
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6  text-left flex-column">
              <UnderLine style={{ margin: "unset" }} />
              <p className="text-left section7__title">
                {whyData.length !== 0 && whyData[0].fields.title}
              </p>
              <p className="section7__text">
                {whyData.length !== 0 && whyData[0].fields.description}
              </p>
              <Button
                type="button"
                className={classes.learnMoreButton}
                onClick={() =>
                  this.onClickLearnmore2(
                    firstData.length !== 0 && firstData[0].fields.description
                  )
                }
              >
                Learn More
                <RightArrow className={classes.findButtonIcon} />
                <img
                  className={classes.findButtonIcon}
                  src={require("../../assets/SVG/right-arrow1.svg")}
                  alt=""
                />
              </Button>
            </div>
            <div className="col-12 col-sm-12 col-md-6 center-align text-center d-flex d-sm-block d-md-none">
              <img
                style={{ width: "100%", margin: "15px 0 5px 10px" }}
                src={require("../../assets/SVG/why-it-works.svg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className=" section8 container  ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6  text-left flex-column d-flex justify-content-end">
              <UnderLine style={{ margin: "unset" }} />
              <p className="text-left section7__title">
                {whenData.length !== 0 && whenData[0].fields.title}
              </p>
              <p className="section7__text">
                {whenData.length !== 0 && whenData[0].fields.description}
              </p>
              <Button
                type="button"
                className={classes.learnMoreButton}
                onClick={() =>
                  this.onClickLearnmore1(
                    firstData.length !== 0 && firstData[0].fields.description
                  )
                }
              >
                Learn More
                <RightArrow className={classes.findButtonIcon} />
                <img
                  className={classes.findButtonIcon}
                  src={require("../../assets/SVG/right-arrow1.svg")}
                  alt=""
                />
              </Button>
            </div>
            <div className="col-12 col-sm-12 col-md-6 center-align text-center">
              <img
                style={{ width: "100%", margin: "0 0 5px 10px" }}
                src={require("../../assets/SVG/when-it-works.svg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className=" section9 text-center">
          <UnderLine />
          <p className="section9__title">Featured Use Case</p>

          <CaseSlider />

          <Button
            type="button"
            className={classes.caseButton}
            onClick={this.routeChange}
          >
            Learn More
            <RightArrow className={classes.findButtonIcon} />
            <img
              className={classes.findButtonIcon}
              src={require("../../assets/SVG/right-arrow1.svg")}
              alt=""
            />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log("23", state);
  return {
    // projects: state.firestore.ordered.projects,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications
  };
};

export default withStyles(styles)(
  compose(
    connect(mapStateToProps)
    // firestoreConnect([
    //   { collection: "projects", orderBy: ["createdAt", "desc"] },
    //   { collection: "notifications", limit: 3, orderBy: ["time", "desc"] }
    // ])
  )(Dashboard)
);

// export default Dashboard;
