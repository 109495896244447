import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ChangePassword from "../../components/ChangePassword";
import { Redirect } from "react-router-dom";
import "./styles.scss";




class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false
    };
  }


  convert(t){

    // Unixtimestamp
    var unixtimestamp = t;
   
    // Months array
    var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
   
    // Convert timestamp to milliseconds
    var date = new Date(unixtimestamp*1000);
   
    // Year
    var year = date.getFullYear();
   
    // Month
    var month = months_arr[date.getMonth()];
   
    // Day
    var day = date.getDate();
   
    // Hours
    var hours = date.getHours();
   
    // Minutes
    var minutes = "0" + date.getMinutes();
   
    // Seconds
    var seconds = "0" + date.getSeconds();
   
    // Display date time in MM-dd-yyyy h:m:s format
    var convdataTime = month+'-'+day+'-'+year;
    
    return convdataTime;
    
   }


  openChange = () => {
    this.setState({
      change: true
    });
  };

  onClose = () => {
    this.setState({
      change: false
    });
  };

  render() {
    const { auth, profile } = this.props;
    if (!auth.uid) return <Redirect to="/" />;
    return (
      <div className="profile container">
        {this.state.change && (
          <ChangePassword
            auth={auth}
            open={this.state.change}
            onClose={this.onClose}
          />
        )}
        <p className="profile__title">Billing Details</p>
        <div className="d-flex align-items-center p-2">
            <p style={{color: "red", "text-decoration": "underline"}}> Cancel Account </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Billing);
