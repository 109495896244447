export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            // firstName: newUser.firstName,
            // lastName: newUser.lastName,
            // initials: newUser.firstName[0] + newUser.lastName[0],
            token: newUser.token,
            email: newUser.email,
            keywords: newUser.keywords,
            paid: newUser.paid,
            username: "",
            country: "",
            city: "",
            zip: ""
            //  keywords: ["123", "234"]
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const userPaidSet = userInfo => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    // firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(newUser.email, newUser.password)
    //   .then(resp => {
    //     console.log("11", newUser, resp);
    //     return
    firestore
      .collection("users")
      .doc(userInfo.uid)
      .update({
        // firstName: newUser.firstName,
        // lastName: newUser.lastName,
        // initials: newUser.firstName[0] + newUser.lastName[0],
        token: userInfo.token,
        //    email: newUuserInfoser.email,
        //    keywords: userInfo.keywords,
        paid: userInfo.paid,
        username: userInfo.username,
        country: userInfo.country,
        city: userInfo.city,
        zip: userInfo.zip,
        phone: userInfo.phone
        //  keywords: ["123", "234"]
      })
      // })
      .then(() => {
        dispatch({ type: "PAID_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "PAID_ERROR", err });
      });
  };
};
