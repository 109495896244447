import React, { Component } from "react";
import {Helmet} from "react-helmet";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import YouTube from 'react-youtube';

import "./styles.scss";
import axios from "axios";



const Video = styled.div`
  width: 100% !important;
  height: auto !important;

`;



const opts = {
  height: '500px',
  width: '100% !important',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};


class Webinar extends Component {

  state = {
    pageTitle : '',
    pageMeta : '',
    canonicalUrl: '',
    team: [],
    assetData: []
  };

  getImageBannerUrl = id => {
    let url = "";
    this.state.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };



  componentDidMount() {

    axios
    .get(
      "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/XGlyXNPpwYDIP2FQKlCZS?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
    )
    .then(res => {
      this.setState({ pageMeta: res.data.fields.pageMeta }); 
      this.setState({ pageTitle: res.data.fields.pageTitle }); 
      this.setState({ canonicalUrl: res.data.fields.canonicalUrl }); 
    });
    axios
    .get(
      "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=team"
    )
    .then(res => {
      this.setState({
        team: res.data.items,
        assetData: res.data.includes,
        
    });
    // console.log(this.state.assetData[0].fields.file.url);
  });
}


  



  render() {
    return (
      <div>

<Helmet>
                <meta charSet="utf-8" />
                <title> {this.state.pageTitle} </title>
                <meta name="description" content={this.state.pageMeta} />
                <link rel="canonical" href={this.state.canonicalUrl} />



            </Helmet>
            
            <div className="single__main container">
          <div className="row justify-content-center">
          <h1 className="faq__title"></h1>
  <Video>
          <YouTube
        videoId="ykexqlNeczw"
        opts={opts}
      />
</Video>



            </div></div>


        
          

   
  
      </div>
    );
  }
}

export default Webinar;
