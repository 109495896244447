import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "./styles.scss";
import axios from "axios";
import ReactPlayer from "react-player";
import Gallery from "react-photo-gallery";
import CompanySlider from "../../components/CompanySlider";
import { Button } from "@material-ui/core";
import { Refresh } from "../../components/Icon";
import { withStyles } from "@material-ui/core/styles";

const UnderLine = styled.div`
  width: 70px;
  height: 4px;
  background-color: #425bff;
  margin: 0 auto;
`;

const styles = theme => ({
  findButtonIcon: {
    height: "14px",
    margin: "0 0 0 10px"
  },
  findButton: {
    width: "100%",
    height: "100%",
    color: "#1d48f5",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreBusinessButtonIcon: {
    height: "20px",
    margin: "0 10px 0 0px",
    color: "#1d48f5"
  },
  learnMoreBusinessButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& svg": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& svg": {
        display: "block"
      },
      "& img": {
        display: "none"
      }
    }
  }
});

class Testimonials extends Component {
  state = {
    pageTitle: "",
    pageMeta: "",
    canonicalUrl: "",
    reviewArray: [],
    assetData: [],
    loadmore: false
  };

  getImageBannerUrl = id => {
    let url = "";
    console.log(this.state.assetData);
    this.state.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  onClickLoadmore = () => {
    this.setState({
      loadmore: !this.state.loadmore
    });
  };

  componentDidMount() {
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=testimonials"
      )
      .then(res => {
        this.setState({
          reviewArray: res.data.items,
          assetData: res.data.includes.Asset
        });
      });
  }

  render() {
    const { loadmore, reviewArray } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {this.state.pageTitle} </title>
          <meta name="description" content={this.state.pageMeta} />
          <link rel="canonical" href={this.state.canonicalUrl} />
        </Helmet>

        <div className="testimonials">
          <div className="testimonials__underline " />
          <p className="testimonials__title">Testimonials</p>
          <p className="testimonials__text">
            Highlights from testimonials, letters and reviews we have received
            from our clients.
          </p>
        </div>
        <div
          className="container review"
          style={
            loadmore
              ? { height: "max-content" }
              : { height: "800px", overflowY: "hidden" }
          }
        >
          {loadmore && (
            <img
              className="review__background"
              src={require("../../assets/images/testimg.png")}
              alt=""
            />
          )}
          <div
            className={loadmore ? "reviewItem1" : "reviewItem2"}
            style={{ marginTop: "0px" }}
          >
            <UnderLine style={{ margin: "unset" }} />
            <p className="text-left review__title">
              Thank you for sharing <br />the love.
            </p>
            <p className="text-left review__text">
              We are greateful to be a part of your sucsess!
            </p>
          </div>
          {reviewArray &&
            reviewArray.map((review, index) => (
              <div
                className={loadmore ? "reviewItem1" : "reviewItem2"}
                key={index}
              >
                <img
                  className="reviewImg"
                  src={this.getImageBannerUrl(
                    review.fields.reviewerImage.sys.id
                  )}
                  alt=""
                />
                <div className="reviewBody">
                  <p className="reviewBody__text">{review.fields.review}</p>
                  <p className="reviewBody__name">
                    {review.fields.reviewerName}
                  </p>
                  <p className="reviewBody__position">
                    {review.fields.reviewerPosition}
                  </p>
                </div>
              </div>
            ))}
          {!loadmore && (
            <div className="review__hide">
              <Button
                type="button"
                className={classes.learnMoreBusinessButton}
                onClick={this.onClickLoadmore}
              >
                <img
                  className={classes.learnMoreBusinessButtonIcon}
                  src={require("../../assets/SVG/refresh.svg")}
                  alt=""
                />
                <Refresh className={classes.learnMoreBusinessButtonIcon} />
                View More
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Testimonials);
