import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import "./style.scss";

const styles = theme => ({
  item: {
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    padding: "15px 20px 15px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    width: "50px",
    height: "50px"
  },
  text: {
    fontSize: "18px",
    lineHeight: "1.56",
    color: "#505966"
  }
});

class BussinessItem extends Component {
  render() {
    const { img, text } = this.props;
    return (
      <div style={{ height: "100px" }}>
        <div className="item">
          <img src={img} alt="" className="item__img" />
          <p className="item__text">{text}</p>
        </div>
      </div>
    );
  }
}

class Industries extends Component {
  render() {
    const { classes, isPad, isMobile } = this.props;
    let { showCount } = this.props;

    return (
      <div className="industries container">
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/accountant.svg")}
            text="Accountants"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/acupuncture.svg")}
            text="Acupuncturists"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/bloger.svg")}
            text="Bloggers"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/brieder.svg")}
            text="Bridal Services"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/cleaning.svg")}
            text="Cleaning Services"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/chiroprsctors.svg")}
            text="Chiropractors"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/life-caoch.svg")}
            text="Coaches"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/contrsctor.svg")}
            text="Contractors"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/dentinst.svg")}
            text="Dentists"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/dispensaries.svg")}
            text="Dispensaries"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/e-commerce.svg")}
            text="E-commerce"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/education.svg")}
            text="Education"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/idea.svg")}
            text="Enterpreneurs"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/finance.svg")}
            text="Finance"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/furniture.svg")}
            text="Furniture"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/gyum.svg")}
            text="Gyms"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/government.svg")}
            text="Government"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/home-garden.svg")}
            text="Home & Garden"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/havac.svg")}
            text="HVAC"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/law.svg")}
            text="Law Firms"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/limo.svg")}
            text="Limo Service"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/lock-smith.svg")}
            text="Locksmiths"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/marketing.svg")}
            text="Marketing"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/masages.svg")}
            text="Masseuses"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/medical.svg")}
            text="Medical Practice"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/optometrist.svg")}
            text="Optometrists"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/osteo.svg")}
            text="Osteopaths"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/parenting.svg")}
            text="Parenting"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/periode.svg")}
            text="Periodontists"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/personal-trine.svg")}
            text="Personal Trainer"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/pet-care.svg")}
            text="Pet Care"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/photograoher.svg")}
            text="Photographers"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/plumber.svg")}
            text="Plumbers"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/pool-service.svg")}
            text="Pool Services"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/realstate.svg")}
            text="Real Estate"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/recruiter.svg")}
            text="Recruiters"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/therapist.svg")}
            text="Therapists"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/restaurent.svg")}
            text="Restaurants"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/roofer.svg")}
            text="Roofers"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/seo.svg")}
            text="Seo Agencies"
          />
        </div>
        <div>
          <BussinessItem
            img={require("../../assets/SVGIcons/software.svg")}
            text="Software"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/spa.svg")}
            text="Spas"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/texhnology.svg")}
            text="Technology"
          />
          <BussinessItem
            img={require("../../assets/SVGIcons/webdesign.svg")}
            text="Web Designers"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Industries);
