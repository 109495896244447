import React, { Component } from "react";

import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";
import "./styles.scss";

class CustomizedDot extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { cx, cy } = this.props;
    return (
      <circle
        className="CustomizedDot"
        cx={cx}
        cy={cy}
        r={8}
        stroke="#2838ed"
        strokeWidth={2}
        fill="white"
      />
    );
  }
}

export default CustomizedDot;
