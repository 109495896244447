import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import firebase from "firebase/app";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  TextField
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import "./styles.scss";

const styles = theme => ({
  change: {
    width: "120px",
    height: "40px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    borderRadius: "22px",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "20px 0 0 0",
    textTransform: "none",
    backgroundImage: "linear-gradient(to right, #3e1dde, #34a5ec)",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)"
  }
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      password: "",
      passwordconfirm: "",
      confirmError: false,
      confirmErrorText: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.location.pathname !== nextProps.location.pathname &&
      !this.props.auth.uid
    ) {
      this.onClose();
    }
  }

  onClose = () => {
    this.setState({
      open: false
    });
    this.props.onClose();
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    this.setState({
      confirmError: false,
      confirmErrorText: ""
    });
    const {
      password,
      passwordconfirm,
      confirmError,
      confirmErrorText
    } = this.state;
    if (password !== "" && password === passwordconfirm) {
      var user = firebase.auth().currentUser;
      user
        .updatePassword(password)
        .then(() => {
          this.onClose();
          this.setState({
            confirmError: false,
            open: false,
            password: "",
            passwordconfirm: ""
          });
        })
        .catch(error => {
          this.setState({
            confirmErrorText: error.message,
            confirmError: true
          });
        });
    } else {
      this.setState({
        confirmError: true
      });
    }
  };

  render() {
    const { classes, open, onClose, history } = this.props;
    const {
      password,
      passwordconfirm,
      confirmError,
      confirmErrorText
    } = this.state;
    const { auth } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        maxWidth="lg"
        className="changepssword"
      >
        <DialogContent
          className="changepsswordBody"
          style={{ padding: "unset" }}
        >
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="changepsswordBody__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>

          <div className="changeHeader">
            <p className="changeHeader__text">Change Password</p>
          </div>
          <div className="changepsswordBody">
            <p
              className="changepsswordBody__logintext"
              style={{ marginTop: "0px" }}
            >
              New Password
            </p>
            <div className="input-group changepsswordBody__inputForm">
              <input
                type="password"
                className=""
                id="password"
                placeholder=""
                onChange={this.handleChange}
              />
            </div>
            <p className="changepsswordBody__logintext">Confirm Password</p>
            <div className="input-group changepsswordBody__inputForm">
              <input
                type="password"
                className=""
                id="passwordconfirm"
                placeholder=""
                onChange={this.handleChange}
              />
            </div>
            <div className="errorChange">
              {confirmError && (
                <p>
                  {confirmErrorText}
                  <br />
                  Please enter correct password!
                </p>
              )}
            </div>
            <div className="changebtn row justify-content-center p-2">
              <Button className={classes.change} onClick={this.handleSubmit}>
                Change
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ChangePassword);
