import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import "./style.scss";

import styled from 'styled-components';



const Hover = styled.p`
	color: #000;
	:hover {

        font-weight: bold;
    color: #1d48f5;
    cursor: pointer;
    text-decoration: underline;

	}
`


const styles = theme => ({
  root: {
    display: "flex !important",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  slider: {
    backgroundColor: "#f6f7fc"
  },
  padSlider: {
    width: "800px"
  },
  mobileSlider: {
    width: "400px"
  }
});

class CaseSlider extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      width: props.width,
      blogData: [],
      assetData: [],
      entryData: []
    };
    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentDidMount() {
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=blogs"
      )
      .then(res => {
        var data = [];
        res.data.items.map(item => {
          if (item.fields.tag[0] === "usecase") {
            data.push(item);
          }
        });
        this.setState({
          blogData: data,
          assetData: res.data.includes.Asset,
          entryData: res.data.includes.Entry
        });
      });
  }

  componentWillMount() {
    this.updateScreenWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }
  updateScreenWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  getImageBannerUrl = id => {
    let url = "";
    this.state.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  onClickBlog = blog => {
    if (blog.fields.tag[0] === "usecase") {
      this.props.history.push({
        pathname: `/blog/${blog.fields.slug}`,
        state: {
          blog: blog,
          entryData: this.state.entryData,
          assetData: this.state.assetData
        }
      });
    }
  };

  render() {
    const { classes, isPad, isMobile } = this.props;
    let { showCount } = this.props;
    const { width, blogData, assetData, entryData } = this.state;

    const settings = {
      className: "caseSlider",
      centerMode: true,
      centerPadding: `${width / 4}px`,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      focusOnSelect: true,
      dots: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            centerPadding: "100px",
            arrows: false,
            //     centerMode: true,
            centerPadding: "40px",
            slidesToShow: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: "40px",
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        {blogData.map((item, key) =>
          item.fields.tag[0] === "usecase" ? (
            <div
              className="case"
              onClick={() => this.onClickBlog(item)}
              key={key}
            >
              <img
                //  src={require("../../assets/SVG/ialicante-mediterranean-homes-475777-unsplash.png")}
                src={this.getImageBannerUrl(item.fields.sliderImage.sys.id)}
                alt=""
                className="case__img "
              />
              <div className="case__body">
                <Hover className="text-md-left case__title">
                  {item.fields.pageTitle}
                </Hover>
                <p className="text-md-left case__text">{item.fields.summary}</p>
              </div>
            </div>
          ) : null
        )}
      </Slider>
    );
  }
}

export default withRouter(withStyles(styles)(CaseSlider));
{
  /* <div className="case">
  <img
    src={require("../../assets/SVG/ialicante-mediterranean-homes-475777-unsplash.png")}
    alt=""
    className="case__img "
  />
  <div className="case__body">
    <p className="text-md-left case__title">Real Estate</p>
    <p className="text-md-left case__text">
      Real estate agents need to be visible in Google for their
      location. If you’re serving the Los Angeles area, for example,
      you’ll want to be sure to be found on page 1 of Google for Los
      Angeles Real Estte Agent which has a monthly search volume of 240
      nationwide and 440 globally.
    </p>
  </div>
</div>
<div className="case">
  <img
    src={require("../../assets/SVG/ialicante-mediterranean-homes-475777-unsplash.png")}
    alt=""
    className="case__img "
  />
  <div className="case__body">
    <p className="text-md-left case__title">Real Estate</p>
    <p className="text-md-left case__text">
      Real estate agents need to be visible in Google for their
      location. If you’re serving the Los Angeles area, for example,
      you’ll want to be sure to be found on page 1 of Google for Los
      Angeles Real Estte Agent which has a monthly search volume of 240
      nationwide and 440 globally.
    </p>
  </div>
</div>
<div className="case">
  <img
    src={require("../../assets/SVG/ialicante-mediterranean-homes-475777-unsplash.png")}
    alt=""
    className="case__img "
  />
  <div className="case__body">
    <p className="text-md-left case__title">Real Estate</p>
    <p className="text-md-left case__text">
      Real estate agents need to be visible in Google for their
      location. If you’re serving the Los Angeles area, for example,
      you’ll want to be sure to be found on page 1 of Google for Los
      Angeles Real Estte Agent which has a monthly search volume of 240
      nationwide and 440 globally.
    </p>
  </div>
</div> */
}

{
  /* <div className="case">
  <img
    src={require("../../assets/SVG/ialicante-mediterranean-homes-475777-unsplash.png")}
    alt=""
    className="case__img "
  />
  <div className="case__body">
    <p className="text-md-left case__title">Real Estate</p>
    <p className="text-md-left case__text">
      Real estate agents need to be visible in Google for their location. If
      you’re serving the Los Angeles area, for example, you’ll want to be sure
      to be found on page 1 of Google for Los Angeles Real Estte Agent which has
      a monthly search volume of 240 nationwide and 440 globally.
    </p>
  </div>
</div>; */
}
