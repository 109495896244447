import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "./styles.scss";
import axios from "axios";
import ReactPlayer from "react-player";
import { Button } from "@material-ui/core";

class TopicFidner extends Component {
  constructor() {
    super();
    this.state = {
      keyword: "",
      loadmore: false,
      firstData: [],
      stepsData: [],
      whyData: [],
      whenData: [],
      pageTitle: "",
      pageMeta: "",
      canonicalUrl: "",
      firstText: "",
      topDescription: "",
      underSearch: "",
      button1: ""
    };
    this.keywordChange = this.keywordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getImageBannerUrl = id => {
    let url = "";
    this.props.location.state.assetData.map(data => {
      if (id === data.sys.id) {
        url = data.fields.file.url;
      }
    });
    return url;
  };

  componentDidMount() {
    // axios
    //   .get(
    //     "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/1QPcMoDZPqN93M0g3QnXD8?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
    //   )
    //   .then(res => {
    //     this.setState({ pageMeta: res.data.fields.pageMeta });
    //     this.setState({ pageTitle: res.data.fields.pageTitle });
    //     this.setState({ canonicalUrl: res.data.fields.canonicalUrl });
    //   });
    // axios
    //   .get(
    //     "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=team"
    //   )
    //   .then(res => {
    //     this.setState({
    //       team: res.data.items,
    //       assetData: res.data.includes
    //     });
    //     // console.log(this.state.assetData[0].fields.file.url);
    //   });
  }

  keywordChange = e => {
    if (e.key === "Enter") {
      this.findButton.click();
    }
    this.setState({
      keyword: e.target.value
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.keyword !== "") {
      this.props.history.push({
        pathname: `/topic-finder/${this.state.keyword}`,
        keyword: this.state.keyword
      });
    }
  }

  render() {
    const { keyword } = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {this.state.pageTitle} </title>
          <meta name="description" content={this.state.pageMeta} />
          <link rel="canonical" href={this.state.canonicalUrl} />
        </Helmet>

        <div className="topicfinder">
          <div className="container row">
            <div className="col-12 col-xl-6 d-flex flex-column justify-content-center">
              <div className="topicfinder__underline " />
              <p className="topicfinder__title">Blogger's Best Friend</p>
              <p className="topicfinder__text">
                The amazing FREE tool to find all of your greatest keywords for
                your upcomming blog posts and content.
              </p>
            </div>
            <div className="col-12 col-xl-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=W-oQ58CAYFU"
                // url={this.findVideoUrl(video)}
                muted
                loop
                className="topicfinder__video"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="topicFinderBody">
            <p className="topicFinderBody__title">Hi there!</p>
            <p className="topicFinderBody__text">
              What is your blog post about?
            </p>
            <input
              type="text"
              className="topicFinderBody__input"
              placeholder=""
              value={keyword}
              onKeyPress={this.keywordChange}
              onChange={this.keywordChange}
            />
            <div
              className="topicFinderBodyNext row d-flex justify-content-end"
              onClick={this.handleSubmit}
              ref={el => (this.findButton = el)}
            >
              <Button className=" col-4 col-sm-10 col-md-8 col-lg-6 col-xl-4">
                <span className="topicFinderBodyNext__text">
                  Show me some great keywords
                </span>
                <img
                  style={{ height: "14px", margin: "0 0 0 0px" }}
                  src={require("../../assets/SVG/right-arrow1.svg")}
                  alt=""
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopicFidner;
