import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import DotLoader from "react-spinners/DotLoader";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, Button } from "@material-ui/core";
import { IntercomAPI } from "react-intercom";
import { countries } from "country-data";
import axios from "axios";
import _ from "lodash";
import styled from "styled-components";
import { Elements, StripeProvider } from "react-stripe-elements";

import KeywordsTable from "../../components/KeywordsTable";
// import CheckoutForm from "./CheckoutForm";
import Signup from "../../components/Signup";
import Login from "../../components/Login";

import "./index.scss";

var sort = "desc";
var isAsc = false;
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8"
  },
  tabsIndicator: {
    backgroundColor: "#1890ff"
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1
    },
    "&$tabSelected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#40a9ff"
    }
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3
  },
  easyrow: {
    backgroundColor: "#E9FFE5"
  },
  mediumrow: {
    backgroundColor: "#FFFDEA"
  },
  hardrow: {
    backgroundColor: "#FFEDED"
  }
});

const loader = styled.div`
  width: 70px;
  height: 4px;
  background-color: #425bff;
  margin: 0 auto;
`;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      url: "",
      data: null,
      score: 0,
      headerData: null,
      selectedKeywordArray: [],
      checkedItems: new Map(),
      openSignup: false,
      openLogin: false,
      width: props.width,
      redarray: [],
      yellowarray: [],
      greenarray: [],
      difficultyFlag: false
    };
    window.addEventListener("resize", this.updateScreenWidth);
    // window.addEventListener("click", this.testInter);
  }

  componentDidUpdate(prevProps) {
    //  console.log("123123", this.props.location);
    // if (this.props.location !== prevProps.location) {
    //   window.scrollTo(0, 0);
    // }
  }

  componentWillMount() {
    this.updateScreenWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }
  updateScreenWidth = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  testInter = e => {
    console.log("intercome event");
    IntercomAPI("trackEvent", "schedule-consultation");
    var x = parseInt(new Date().getTime() / 1000);
    setTimeout(
      function() {
        IntercomAPI("update");
        console.log("Update");
      }.bind(this),
      1000
    );

    // window.Intercom('update', {last_request_at: 2});

    // IntercomAPI('update');
    // IntercomAPI('hide');
  };

  componentDidMount() {
    this.setState({
      url: this.props.location.url
    });
    if (this.props.location.url === undefined) {
      this.props.history.push({
        pathname: "/"
      });

      const baseUrl =
        //  "https://4a6ziddyc1.execute-api.us-east-1.amazonaws.com/staging?url=https://yakkyofy.com";
        "https://4a6ziddyc1.execute-api.us-east-1.amazonaws.com/staging?url=https://cubahaus.com";

      axios.get(baseUrl).then(res => {
        if (res.data !== "No data") {
          var temp = _.orderBy(res.data, "competition", "desc");
          var greenarray = [];
          var yellowarray = [];
          var redarray = [];
          temp.map((item, key) => {
            if (item.competition > 0.66) {
              redarray.push(item);
            } else if (item.competition > 0.33) {
              yellowarray.push(item);
            } else {
              greenarray.push(item);
            }
          });
          var sortedgreenarray = _.orderBy(greenarray, "search_volume", "desc");
          var sortedyellowarray = _.orderBy(
            yellowarray,
            "search_volume",
            "desc"
          );
          var sortedredarray = _.orderBy(redarray, "search_volume", "desc");

          if (res.data.length < 10) {
            this.testInter();
          }

          temp = sortedgreenarray;
          temp = temp.concat(sortedyellowarray);
          temp = temp.concat(sortedredarray);
          console.log("temp123", temp);
          let score = 0;
          res.data !== "No data" &&
            res.data.map(keyword => {
              if (keyword.position === 1) score += 1;
            });
          this.setState({
            redarray: sortedredarray,
            yellowarray: sortedyellowarray,
            greenarray: sortedgreenarray,
            data: temp,
            score: (score * 100) / res.data.length
          });
        }
      });
      axios
        .get(
          "https://etedvkwkd3.execute-api.us-east-1.amazonaws.com/staging?url=" +
            this.props.location.url
        )
        .then(res => {
          this.setState({ headerData: res.data });
        });
    } else {
      const baseUrl =
        //  "https://4a6ziddyc1.execute-api.us-east-1.amazonaws.com/staging?url=https://yakkyofy.com";
        "https://4a6ziddyc1.execute-api.us-east-1.amazonaws.com/staging?url=" +
        this.props.location.url;
      axios.get(baseUrl).then(res => {
        if (res.data !== "No data") {
          var temp = _.orderBy(res.data, "competition", "desc");
          var greenarray = [];
          var yellowarray = [];
          var redarray = [];
          temp.map((item, key) => {
            if (item.competition > 0.66) {
              redarray.push(item);
            } else if (item.competition > 0.33) {
              yellowarray.push(item);
            } else {
              greenarray.push(item);
            }
          });
          var sortedgreenarray = _.orderBy(greenarray, "search_volume", "desc");
          var sortedyellowarray = _.orderBy(
            yellowarray,
            "search_volume",
            "desc"
          );
          var sortedredarray = _.orderBy(redarray, "search_volume", "desc");

          if (res.data.length < 10) {
            this.testInter();
          }

          temp = sortedgreenarray;
          temp = temp.concat(sortedyellowarray);
          temp = temp.concat(sortedredarray);
          console.log("temp123", temp);
          let score = 0;
          res.data !== "No data" &&
            res.data.map(keyword => {
              if (keyword.position === 1) score += 1;
            });
          this.setState({
            redarray: sortedredarray,
            yellowarray: sortedyellowarray,
            greenarray: sortedgreenarray,
            data: temp,
            score: (score * 100) / res.data.length
          });
        }
      });
      axios
        .get(
          "https://etedvkwkd3.execute-api.us-east-1.amazonaws.com/staging?url=" +
            this.props.location.url
        )
        .then(res => {
          this.setState({ headerData: res.data });
        });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  selectKey = (event, id) => {
    var array = [...this.state.selectedKeywordArray];
    var index = array.indexOf(id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedKeywordArray: array });
    } else {
      this.setState({
        selectedKeywordArray: [...this.state.selectedKeywordArray, id]
      });
    }
  };

  nextStep = () => {
    this.setState({
      // value: 1,
      openSignup: true
    });
    // this.props.history.push({
    //   pathname: "/signup",
    //   state: {
    //     // cardid: this.state.cardid,
    //     keywords: this.state.selectedKeywordArray
    //   }
    // });
  };

  setSelectedKeywordArray = arrayData => {
    this.setState({ selectedKeywordArray: arrayData });
  };

  onClose = e => {
    this.setState({ openSignup: false, openLogin: false });
  };

  open = e => {
    this.setState({ openSignup: false, openLogin: true });
  };

  keywordSort = () => {
    sort = isAsc ? "desc" : "asc";
    console.log("Sort otder", sort);

    var sortedgreenarray = _.orderBy(this.state.greenarray, "key", sort);
    var sortedyellowarray = _.orderBy(this.state.yellowarray, "key", sort);
    var sortedredarray = _.orderBy(this.state.redarray, "key", sort);

    var temp = sortedgreenarray;
    temp = temp.concat(sortedyellowarray);
    temp = temp.concat(sortedredarray);
    console.log("temp", temp);

    this.setState({
      redarray: sortedredarray,
      yellowarray: sortedyellowarray,
      greenarray: sortedgreenarray,
      data: temp
    });

    isAsc = !isAsc;
  };

  volumeSort = () => {
    console.log(isAsc);
    console.log(sort);

    sort = isAsc ? "desc" : "asc";
    console.log("Sort otder", sort);

    var sortedgreenarray = _.orderBy(
      this.state.greenarray,
      "search_volume",
      sort
    );
    var sortedyellowarray = _.orderBy(
      this.state.yellowarray,
      "search_volume",
      sort
    );
    var sortedredarray = _.orderBy(this.state.redarray, "search_volume", sort);

    var temp = sortedgreenarray;
    temp = temp.concat(sortedyellowarray);
    temp = temp.concat(sortedredarray);
    console.log("temp", temp);

    this.setState({
      redarray: sortedredarray,
      yellowarray: sortedyellowarray,
      greenarray: sortedgreenarray,
      data: temp
    });

    isAsc = !isAsc;
  };

  positionSort = () => {
    sort = isAsc ? "desc" : "asc";

    var sortedgreenarray = _.orderBy(this.state.greenarray, "position", sort);
    var sortedyellowarray = _.orderBy(this.state.yellowarray, "position", sort);
    var sortedredarray = _.orderBy(this.state.redarray, "position", sort);

    var temp = sortedgreenarray;
    temp = temp.concat(sortedyellowarray);
    temp = temp.concat(sortedredarray);
    console.log("temp", temp);

    this.setState({
      redarray: sortedredarray,
      yellowarray: sortedyellowarray,
      greenarray: sortedgreenarray,
      data: temp
    });

    isAsc = !isAsc;
  };

  difficultySort = () => {
    const temp = [];
    if (this.state.difficultyFlag) {
      temp.push(...this.state.greenarray);
      temp.push(...this.state.yellowarray);
      temp.push(...this.state.redarray);
    } else {
      temp.push(...this.state.redarray);
      temp.push(...this.state.yellowarray);
      temp.push(...this.state.greenarray);
    }
    console.log("temp", temp);

    this.setState({
      data: temp,
      difficultyFlag: !this.state.difficultyFlag
    });
  };

  render() {
    const { classes } = this.props;
    const {
      value,
      score,
      data,
      headerData,
      selectedKeywordArray,
      url,
      width
    } = this.state;

    console.log("data", data);
    return (
      <div className=" ">
        <Signup
          open={this.state.openSignup}
          onClose={this.onClose}
          keywords={this.state.selectedKeywordArray}
          openLogin={this.open}
        />
        <Login open={this.state.openLogin} onClose={this.onClose} />
        <div>
          <LoadingOverlay
            active={!headerData}
            spinner={
              <DotLoader
                sizeUnit={"px"}
                css={"margin-bottom:800px;"}
                size={100}
                color={"#1d48f5"}
              />
            }
          >
            <div className="analyzeTitle">
              <div className="container">
                <div className="row">
                  {/* <div className="col s12 m7 d-flex justify-content-center flex-column"> */}
                  <div className="col-lg-7 col-md-12 py-3">
                    <div className="d-flex align-items-center">
                      <p className="analyzeTitle__number1">1</p>
                      <p className="analyzeTitle__step1">STEP1</p>
                      <div className="analyzeTitle__dashedLine" />
                      <p className="analyzeTitle__number2">2</p>
                      <p className="analyzeTitle__step2">STEP2</p>
                    </div>
                    <p className="analyzeTitle__url">
                      {headerData && data ? headerData.url : url}
                    </p>
                    <div className="analyzeTitle__title d-flex align-items-center">
                      {/* <p>LOREM IPSUME</p>
                    <img
                      style={{ height: "14px", margin: "0 15px" }}
                      src={require("../../assets/SVG/right-arrow.svg")}
                      alt=""
                    />
                    <p>IPSUME</p>
                    <img
                      style={{ height: "14px", margin: " 0 15px" }}
                      src={require("../../assets/SVG/right-arrow.svg")}
                      alt=""
                    /> */}
                      <p>{headerData && data ? headerData.title : ""}</p>
                    </div>
                    <p className="analyzeTitle__meta">
                      {headerData ? headerData.meta : ""}
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-12">
                    <p className="analyzeTitle__country">
                      <span>Country:&nbsp;&nbsp;&nbsp;</span>
                      {data ? countries[data[0].country_code].name : ""}
                    </p>
                    <p className="analyzeTitle__account">
                      <span>Niche:&nbsp;&nbsp;&nbsp;</span>{" "}
                      {headerData ? headerData.niche : ""}
                    </p>
                    <div className="analyzeTitle__muscle">
                      <p>
                        <span>Your site's ranking muscle: </span> <br />(
                        {parseInt(score, 10)}% of your keywords rank on page 1
                        of Google)
                      </p>
                      <img
                        style={{
                          height: "90px",
                          width: "90px",
                          margin: "0 15px"
                        }}
                        src={
                          score > 50
                            ? require("../../assets/SVG/muscale6.svg")
                            : score > 40
                            ? require("../../assets/SVG/muscale7.svg")
                            : score > 30
                            ? require("../../assets/SVG/muscale5.svg")
                            : score > 20
                            ? require("../../assets/SVG/muscale4.svg")
                            : score > 10
                            ? require("../../assets/SVG/muscale3.svg")
                            : score > 5
                            ? require("../../assets/SVG/muscale2.svg")
                            : require("../../assets/SVG/muscale1.svg")
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="tableTitle container">
                    <div className="">
                      <p className="tableTitle__text text-center">
                        Please choose <b> one or more keywords </b> to boost on
                        Google
                      </p>
                      <div className="row tableTitle__smallTitle">
                        <div className="col-6 col-sm-6 col-md-6 text-left">
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          <span
                            className="tableheaderKeyword"
                            onClick={this.keywordSort}
                          >
                            KEYWORD
                          </span>
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 text-left tableTitle__smallTitle__volume">
                          <span
                            className="tableheaderVolume"
                            onClick={this.volumeSort}
                          >
                            VOLUME
                          </span>
                          {/* &nbsp;&nbsp;&nbsp; */}
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 text-left tableTitle__smallTitle__position">
                          <span
                            className="tableheaderPosition"
                            onClick={this.positionSort}
                          >
                            POSITION
                          </span>
                          {/* &nbsp;&nbsp;&nbsp; */}
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 text-left">
                          <span
                            className="tableheaderDifficulty"
                            onClick={this.difficultySort}
                          >
                            DIFFICULTY
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.root}>
              <div>
                <div className="easytable">
                  {data ? (
                    <KeywordsTable
                      width={width}
                      data={data}
                      selectRow={this.selectKey}
                      setSelectedKeywordArray={this.setSelectedKeywordArray}
                    />
                  ) : (
                    <div
                      className="container"
                      style={{
                        height: "546px",
                        width: "100%",
                        borderRadius: "4px",
                        marginBottom: "20px",
                        boxShadow:
                          "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
                      }}
                    />
                  )}
                </div>

                <div className="next row">
                  <div className="col-md-4 text-center">
                    {selectedKeywordArray.length} Keywords Selected
                  </div>
                  <Button className="col-md-2" onClick={this.nextStep}>
                    Next
                    <img
                      style={{ height: "14px", margin: "0 0 0 10px" }}
                      src={require("../../assets/SVG/right-arrow1.svg")}
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(index);
