import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ChangePassword from "../../components/ChangePassword";
import { Redirect } from "react-router-dom";
import "./styles.scss";




class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false
    };
  }


  convert(t){

    // Unixtimestamp
    var unixtimestamp = t;
   
    // Months array
    var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
   
    // Convert timestamp to milliseconds
    var date = new Date(unixtimestamp*1000);
   
    // Year
    var year = date.getFullYear();
   
    // Month
    var month = months_arr[date.getMonth()];
   
    // Day
    var day = date.getDate();
   
    // Hours
    var hours = date.getHours();
   
    // Minutes
    var minutes = "0" + date.getMinutes();
   
    // Seconds
    var seconds = "0" + date.getSeconds();
   
    // Display date time in MM-dd-yyyy h:m:s format
    var convdataTime = month+'-'+day+'-'+year;
    
    return convdataTime;
    
   }


  openChange = () => {
    this.setState({
      change: true
    });
  };

  onClose = () => {
    this.setState({
      change: false
    });
  };

  render() {
    const { auth, profile } = this.props;
    console.log("profile", auth, profile);
    if (!auth.uid) return <Redirect to="/" />;
    return (
      <div className="profile container">
        {this.state.change && (
          <ChangePassword
            auth={auth}
            open={this.state.change}
            onClose={this.onClose}
          />
        )}
        <p className="profile__title">Account Setting</p>
        <div className="d-flex align-items-center p-2">
          <img
            className="profile__img"
            src={require("../../assets/SVG/email.svg")}
            alt=""
          />
          <p className="profile__text">Account Email: {auth.email}</p>
        </div>
        <div className="d-flex align-items-center p-2">
          <img
            className="profile__img"
            src={require("../../assets/SVG/user.svg")}
            alt=""
          />
          <p className="profile__text">
            Your Name: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{profile.username}
          </p>
        </div>
        <div className="d-flex align-items-center p-2 profile__change">
          <div className="d-flex align-items-center">
            <img
              className="profile__img"
              src={require("../../assets/SVG/lock.svg")}
              alt=""
            />
            <p className="profile__text">
              Password: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              ************* 
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <button
              onClick={this.openChange}
              type="button"
              className="btn btn-link change"
            >
              Change
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center p-2">
          <p className="profile__text">
            Member Since: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* {moment.unix(auth.createdAt).format("YYYY-MM-DD HH:mm")} */}
            {/* {auth.createdAt} */}
            {/* {timestamp.toDate('1559928773859')} */}
            { this.convert(auth.createdAt/1000)}
          </p>
        </div>
        <div className="d-flex align-items-center p-2">
          <p className="profile__text">
            Paid: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {profile.paid ? "True" : "False"}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Profile);
