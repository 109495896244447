import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import { signIn } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";

import "./styles.scss";

class Trial extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errorMsg: "",
      open: false,
      url: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      email: "",
      passowrd: ""
    });
  }
  componentWillReceiveProps(nextProps) {
    // const { auth } = nextProps;
    // const { status, error } = auth;
    // if (error) {
    //   this.setState({
    //     errorMsg: auth.error
    //   });
    //   if (status === LOGIN_FAIL) {
    //     this.setState({
    //       usernameError: error.username ? error.username.join() : "",
    //       emailError: error.email ? error.email.join() : ""
    //     });
    //   }
    // }
    // if (status === LOGIN_SUCCESS) {
    //   this.onClose();
    // }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    if (e.key === "Enter") {
      this.findButton.click();
      this.onClose();
    }
  };

  onClose = () => {
    this.setState({
      email: "",
      passowrd: ""
    });
    this.props.onClose();
  };

  handleSubmit(e) {
    e.preventDefault();
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain name
      "\\s*|" + 
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!!pattern.test(this.state.url)) {
      this.props.history.push({
        pathname: "/analyze",
        url: this.state.url
      });
    }

    this.onClose();
  }

  render() {
    const { open, onClose, history } = this.props;
    const {
      username,
      usernameError,
      password,
      passwordError,
      errorMsg,
      url
    } = this.state;
    const valid = username && password;
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/" />;
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        maxWidth="lg"
        // style={{ borderRadius: "25px" }}
        className="trialM"
      >
        <DialogContent className="trial" style={{ padding: "unset" }}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="signup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>

          <div className="trialHeader">
            <img
              style={{ height: "70px", width: "40%" }}
              src={require("../../assets/SVG/logo1.svg")}
              alt=""
            />
            <p className="trialHeader__text text-center">
              Enter your website url to
              <br /> get started
            </p>
          </div>
          <div className="signupBody">
            <p className="signupBody__logintext" style={{ marginTop: "0px" }}>
              Enter Domain
            </p>
            <div className="input-group signupBody__inputForm">
              <input
                type="text"
                id="url"
                placeholder=""
                value={url}
                onChange={this.handleChange}
                onKeyPress={this.handleChange}
              />
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/country.svg")}
                alt=""
              />
            </div>

            <div
              className="next row"
              ref={el => (this.findButton = el)}
              onClick={this.handleSubmit}
            >
              <Button className="col-md-5">
                Get Keywords
                <img
                  style={{ height: "14px", margin: "0 0 0 10px" }}
                  src={require("../../assets/SVG/right-arrow1.svg")}
                  alt=""
                />
              </Button>
              <div className="center red-text">
                {authError ? <p>{authError}</p> : null}
              </div>
            </div>
            {/* <p className="signupBody__buttomtext text-center">
              Already Have an Account? <span>Create My Account</span>
            </p> */}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Trial)
);
