import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Replace this with your own config details

// var firebaseConfig = {
//   apiKey: "AIzaSyDulxMFkes4mfiGaQpUSNnGmd2iLngKb0o",
//   authDomain: "onekeyword-a6a3e.firebaseapp.com",
//   databaseURL: "https://onekeyword-a6a3e.firebaseio.com",
//   projectId: "onekeyword-a6a3e",
//   storageBucket: "onekeyword-a6a3e.appspot.com",
//   messagingSenderId: "684078278848",
//   appId: "1:684078278848:web:2d9e18a8601c993e"
// };

var firebaseConfig = {
  apiKey: "AIzaSyDDTUuPNftx1YfkJtO9SMLEnfiYHF6DsOo",
  authDomain: "one-keyword.firebaseapp.com",
  databaseURL: "https://one-keyword.firebaseio.com",
  projectId: "one-keyword",
  storageBucket: "one-keyword.appspot.com",
  messagingSenderId: "505333751637",
  appId: "1:505333751637:web:fd435e899a773c11"
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  //  timestampsInSnapshots: true
});

export default firebase;
