export const signUpEmail = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log("newUser", newUser);

    firestore
      .collection("emails")
      .add({
        email: newUser.email,
        cookies: newUser.cookies,
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: "EMAIL_SUCCESS", newUser });
      })
      .catch(err => {
        dispatch({ type: "EMAIL_ERROR", err });
      });
  };
};
