import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, Button } from "@material-ui/core";

import { signUp } from "../../store/actions/authActions";

// import NButton from "../ButtonContainer";
// import InputTextField from "../InputTextField";
// import ErrorMessage from "../ErrorMessage";

// import { loginRequest } from "../../actions/auth";
// import { LOGIN_SUCCESS, LOGIN_FAIL } from "../../constants/auth";

// import signinImg from "../../assets/landing/12.svg";
// import signinImg1 from "../../assets/landing/18.svg";
// import facebookIcon from "../../assets/SVG/facebook-logo.svg";
// import googleIcon from "../../assets/SVG/google-plus-symbol.svg";
import "./styles.scss";

const UserIcon = styled.img`
  width: 27px;
  height: 27;
  filter: invert(1);
`;

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMsg: "",
      open: false,
      token: "",
      paid: false
    };
  }

  componentDidMount() {
    this.setState({
      email: "",
      passowrd: ""
    });
  }
  componentWillReceiveProps(nextProps) {
    // const { auth } = nextProps;
    // const { status, error } = auth;
    // if (error) {
    //   this.setState({
    //     errorMsg: auth.error
    //   });
    //   if (status === LOGIN_FAIL) {
    //     this.setState({
    //       usernameError: error.username ? error.username.join() : "",
    //       emailError: error.email ? error.email.join() : ""
    //     });
    //   }
    // }
    // if (status === LOGIN_SUCCESS) {
    //   this.onClose();
    // }
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
      usernameError: false,
      emailError: false,
      passwordError: false,
      keywords: null
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp({
      email: this.state.email,
      password: this.state.password,
      keywords: this.props.keywords,
      token: this.state.token,
      paid: this.state.paid,
      country: "",
      city: "",
      zip: "",
      phone: ""
    });
  };

  onClose = () => {
    this.setState({
      username: "",
      passowrd: ""
    });
    this.props.onClose();
  };

  login = () => {
    this.onClose();
    this.props.openLogin();
  };

  render() {
    const { open, onClose, history, auth, authError } = this.props;
    const {
      email,
      usernameError,
      password,
      passwordError,
      errorMsg
    } = this.state;
    const valid = email && password;
    if (auth.uid) return <Redirect to="/home" />;
    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        maxWidth="lg"
        // style={{ borderRadius: "25px" }}
        className="dialog"
      >
        <DialogContent className="signup" style={{ padding: "unset" }}>
          <IconButton
            style={{
              padding: "unset",
              //    backgroundColor: "#e6e7f5",
              position: "absolute",
              top: 0,
              right: 0
            }}
            className="signup__close"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>

          <div className="signupHeader">
            <div>
              <div className="d-flex align-items-center">
                <p className="analyzeTitle__number1">1</p>
                <p className="analyzeTitle__step1">STEP1</p>
                <div className="analyzeTitle__dashedLine" />
                <p className="analyzeTitle__number1">2</p>
                <p className="analyzeTitle__step1">STEP2</p>
              </div>
              <p className="signupHeader__text">You're almost done.</p>
              <p className="signupHeader__text1">
                Sign up to login to your dashboard.
              </p>
            </div>
            <img
              className="signupHeader__img"
              style={{ height: "290px", width: "40%" }}
              src={require("../../assets/SVG/undraw-authentication-fsn-5.svg")}
              alt=""
            />
          </div>
          <div className="signupBody">
            <p className="signupBody__logintext">Email</p>
            <div className="input-group signupBody__inputForm">
              <input
                type="email"
                id="email"
                className=""
                placeholder=""
                onChange={this.handleChange}
              />
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/email.svg")}
                alt=""
              />
            </div>
            <p className="signupBody__logintext">Password</p>
            <div className="input-group signupBody__inputForm">
              <input
                type="password"
                id="password"
                className=""
                placeholder=""
                onChange={this.handleChange}
              />
              <img
                style={{ height: "24px" }}
                src={require("../../assets/SVG/lock.svg")}
                alt=""
              />
            </div>
            <div className="next row">
              <Button className="col-md-4" onClick={this.handleSubmit}>
                Sign Up
                <img
                  style={{ height: "14px", margin: "0 0 0 10px" }}
                  src={require("../../assets/SVG/right-arrow1.svg")}
                  alt=""
                />
              </Button>
              <div className="center red-text">
                {authError ? <p>{authError}</p> : null}
              </div>
            </div>
            <p className="signupBody__buttomtext text-center">
              Already Have an Account?{" "}
              <button
                type="button"
                className="btn btn-link"
                style={{ color: "#1d48f5" }}
                onClick={this.login}
              >
                Login
              </button>
            </p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: creds => dispatch(signUp(creds))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
