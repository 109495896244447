import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { IconButton, Menu, MenuItem, withStyles } from "@material-ui/core";
import { signOut } from "../../../store/actions/authActions";

const styles = {
  content: {
    maxWidth: 1000,
    width: "90%",
    margin: "0 auto",
    paddingTop: 29
  }
};

class SignedInLinks extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      anchorEl: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname)
      this.handleClose();
  }

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null
    });
  };

  handleOpen = e => {
    e.preventDefault();
    this.setState({
      open: true,
      anchorEl: e.currentTarget
    });
  };

  signOut = () => {
    this.handleClose();
    this.props.signOut();
  };
  render() {
    const { open, anchorEl } = this.state;
    return (
      <div className="">
        <div className="col d-flex flex-row">
          <div
            className="p-2 btn-floating pink lighten-1"
            onClick={this.handleOpen}
          >
            <p className="btn ">{this.props.profile.username}</p>
            <img
              style={{ height: "24px" }}
              src={
                open
                  ? require("../../../assets/SVG/arrow1.svg")
                  : require("../../../assets/SVG/arrow.svg")
              }
              alt=""
            />
          </div>
          <Menu
            style={{ marginTop: "85px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={this.handleClose}
          >
            <MenuItem component={Link} to="/profile">
              Account Settings
            </MenuItem>
            <MenuItem component={Link} to="/billing">
              Billing Details
            </MenuItem>
            <MenuItem onClick={this.signOut}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
    //  notifications: state.firestore.ordered.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default withRouter(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SignedInLinks)
  )
);
