import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import "./style.scss";

const styles = theme => ({
  root: {
    display: "flex !important",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  slider: {
    width: "1200px"
  },
  padSlider: {
    width: "800px"
  },
  mobileSlider: {
    width: "400px"
  }
});

class CompanySlider extends Component {
  render() {
    const { classes, isPad, isMobile } = this.props;
    let { showCount } = this.props;

    return (
      <Slider
        dots={true}
        infinite={true}
        slidesToShow={5}
        speed={500}
        slidesToScroll={1}
        autoplay={true}
        arrows={false}
        className={classes.slider}
      >
        <div className="companys">
          <img
            src={require("../../assets/images/santa-monica.png")}
            alt=""
            className="companys__img responsive-img"
          />
        </div>
        <div className="companys">
          <img
            src={require("../../assets/images/fox-sports.jpg")}
            alt=""
            className="companys__img responsive-img"
          />
        </div>
        <div className="companys">
          <img
            src={require("../../assets/images/t-mobile.jpg")}
            alt=""
            className="companys__img responsive-img"
          />
        </div>
        <div className="companys">
          <img
            src={require("../../assets/images/pacific-park.jpg")}
            alt=""
            className="companys__img responsive-img"
          />
        </div>
        <div className="companys">
          <img
            src={require("../../assets/images/naam.jpg")}
            alt=""
            className="companys__img responsive-img"
          />
        </div>
        <div className="companys">
          <img
            src={require("../../assets/images/stjohns.png")}
            alt=""
            className="companys__img responsive-img"
          />
        </div>

      </Slider>
    );
  }
}

export default withStyles(styles)(CompanySlider);
