import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { RightArrow, Refresh } from "../../components/Icon";
import BlogItem from "../../components/BlogItem";
import BlogMain from "../../components/BlogMain";
import {Helmet} from "react-helmet";
import "./styles.scss";

const styles = theme => ({
  findButtonIcon: {
    height: "14px",
    margin: "0 0 0 10px"
  },
  findButton: {
    width: "100%",
    height: "100%",
    color: "#1d48f5",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  },
  learnMoreBusinessButtonIcon: {
    height: "20px",
    margin: "0 10px 0 0px"
  },
  learnMoreBusinessButton: {
    width: "206px",
    height: "60px",
    color: "#1d48f5",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    fontSize: "16px",
    fontFamily: "DM Sans",
    "& svg": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& svg": {
        display: "block"
      },
      "& img": {
        display: "none"
      }
    }
  },
  caseButton: {
    width: "242px",
    height: "60px",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
    color: "#1d48f5",
    fontSize: "16px",
    //   marginTop: "-50px",
    fontFamily: "DM Sans",
    "& img": {
      display: "none"
    },
    "&:hover": {
      color: "#ffffff",
      backgroundImage: "radial-gradient(circle at 0 89%, #3e1dde, #34a5ec)",
      boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)",
      fill: "#ffffff",
      "& img": {
        display: "block"
      },
      "& svg": {
        display: "none"
      }
    }
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

class Cases extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      loadmore: false,
      blogData: [],
      assetData: [],
      entryData: [],
      offset: 0,
      pageTitle : '',
      pageMeta : '',
      canonicalUrl: ''

    };
    this.onSubmit = this.onSubmit.bind(this);
    this.urlChange = this.urlChange.bind(this);
  }

  
  componentDidMount() {

      
    axios
    .get(
      "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries/3AwM3Oc0K4p1D83XpRXDxz?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA"
    )
    .then(res => {
      this.setState({ pageMeta: res.data.fields.pageMeta }); 
      this.setState({ pageTitle: res.data.fields.pageTitle }); 
      this.setState({ canonicalUrl: res.data.fields.canonicalUrl }); 
    });

    
    axios
      .get(
        "https://cdn.contentful.com/spaces/e9ct5l4tkfzk/entries?access_token=fEOA44KcxnNFrwl5gBfMP-Fea6ouBZE0gSOiOZdCaxA&content_type=blogs"
      )
      .then(res => {
        var data = [];
        res.data.items.map(item => {
          if (item.fields.tag[0] === "usecase") {
            data.push(item);
          }
        });
        this.setState({
          blogData: data,
          assetData: res.data.includes.Asset,
          entryData: res.data.includes.Entry
        });
      });
  }

  handlePageChange(pageNumber) {
    this.setState({ offset: pageNumber });
  }

  urlChange = e => {
    if (e.key === "Enter") {
      this.findButton.click();
    }
    this.setState({
      url: e.target.value
    });
  };

  onSubmit(e) {
    e.preventDefault();
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain name
      "\\s*|" + 
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!!pattern.test(this.state.url)) {
      console.log("url", this.state.url);
      this.props.history.push({
        pathname: "/analyze",
        url: this.state.url
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { url, blogData, assetData, entryData } = this.state;
    var blogDataM = [];
    if (blogData.length > 0) {
      blogDataM = blogData.slice(this.state.offset, this.state.offset + 9);
    }

    return (
      <div className="">
      <Helmet>
                <meta charSet="utf-8" />
                <title> {this.state.pageTitle} </title>
                <meta name="description" content={this.state.pageMeta} />
                <link rel="canonical" href={this.state.canonicalUrl} />



            </Helmet>

        <div
          className="section6 center-align text-center"
          style={{ padding: "68px 0" }}
        >
          <div className="container">
            <div className="section6__underline" />
            <div className=" justify-content-between">
              <p className="section6__text text-left">
                The simplest way to grow
                <br /> your business on <span style={{ color: "#4285f4" }}>G</span>
                <span style={{ color: "#ea4335" }}>o</span>
                <span style={{ color: "#fbbc05" }}>o</span>
                <span style={{ color: "#4285f4" }}>g</span>
                <span style={{ color: "#34a853" }}>l</span>
                <span style={{ color: "#ea4335" }}>e</span>
              </p>
              <div className="input-group section6__findForm">
                <input
                  type="text"
                  className="section6__findForm__input"
                  placeholder="MyWebsite.com"
                  value={url}
                  onKeyPress={this.urlChange}
                  onChange={this.urlChange}
                />

                <span
                  className="input-group-addon section6__findForm__button"
                  ref={el => (this.findButton = el)}
                  onClick={this.onSubmit}
                >
                  <Button type="button" className={classes.findButton}>
                    <div className="section6__findForm__button__text">
                      Find Keywords
                    </div>
                    <RightArrow className={classes.findButtonIcon} />
                    <img
                      className={classes.findButtonIcon}
                      src={require("../../assets/SVG/right-arrow1.svg")}
                      alt=""
                    />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container row" style={{ margin: "0 auto" }}>
          <BlogMain
            blogData={blogDataM}
            assetData={assetData}
            entryData={entryData}
          />
          {blogDataM.map((item, key) =>
            item.fields.tag[0] === "usecase" ? (
              <BlogItem
                data={item}
                key={key}
                assetData={assetData}
                entryData={entryData}
              />
            ) : null
          )}
        </div>
        <div className="pagination">
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={9}
              offset={this.state.offset}
              total={this.state.blogData.length}
              onClick={(e, offset) => this.handlePageChange(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Cases);
