import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import "./style.scss";

const styles = theme => ({
  root: {
    display: "flex !important",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  slider: {
    // width: "1200px"
  },
  padSlider: {
    width: "800px"
  },
  mobileSlider: {
    width: "400px"
  }
});

class LandingSlider extends Component {
  render() {
    const { classes, isPad, isMobile } = this.props;
    let { showCount } = this.props;

    return (
      <Slider
        dots={true}
        infinite={true}
        slidesToShow={1}
        speed={500}
        slidesToScroll={1}
        autoplay={false}
        arrows={false}
        // className={classes.mobileSlider}
      >
        <div className="blog">
          <p className="blog__text text-center">
            “Absolutely brilliant. Thank you for educating me on how to optimize my website. It's been just over 2 months and I am on my way up thanks to you guys. Adding this to my marketing budget was a no-brainer. And I love how passionate you guys are to help me grow my business. I never experience this level of customer service before!”
          </p>
          <img
            src={require("../../assets/avatars/Mel.jpg")}
            alt=""
            className="blog__personAvatar responsive-img"
          />
          <p className="blog__personInfo text-center">
            <span> Mel A.</span> <br />
            Recruiter
          </p>
        </div>
        <div className="blog">
          <p className="blog__text text-center">
            “Wish this service was around sooner! We always knew SEO was important but being a small business the price point was always out of reach. After seeing one of your ads and the money back guarantee we decided to give it a try with just one keyword. We now have 14 keywords and couldn't be happier. Grateful to find a partner that cares and delivers.”
          </p>
          <img
            src={require("../../assets/avatars/josh.jpg")}
            alt=""
            className="blog__personAvatar responsive-img"
          />
          <p className="blog__personInfo text-center">
            <span> Josh M.</span> <br />
            Owner
          </p>
        </div>
        <div className="blog">
          <p className="blog__text text-center">
            “After 2 months of using One Keyword for our e-commerce site we became believers. Not only does the service deliver but their customer service is out of this world. They answered our questions within minutes. Thank you guys, we love working with you!”
          </p>
          <img
            src={require("../../assets/avatars/susan.jpg")}
            alt=""
            className="blog__personAvatar responsive-img"
          />
          <p className="blog__personInfo text-center">
            <span> Susan H.</span> <br />
            Marketing Director
          </p>
        </div>
      </Slider>
    );
  }
}

export default withStyles(styles)(LandingSlider);
