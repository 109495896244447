import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Login from "../../../components/Login";
import Trial from "../../../components/Trial";
import { tsConstructorType } from "@babel/types";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from "react-hamburger-menu";

import { withRouter } from "react-router";
import MenuContent from "../../../components/MenuContent";
import { HeaderArrow } from "../../../components/Icon";
import "./styles.scss";

const styles = theme => ({
  signin: {
    width: "109px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 15px 0 20px",
    backgroundImage: "linear-gradient(to right, #5879ef, #5879ef)",
    textTransform: "none"
  },
  signin1: {
    width: "109px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 15px 0 20px",
    textTransform: "none",
    backgroundImage: "linear-gradient(to right, #3e1dde, #34a5ec)",
    boxShadow: "0 10px 40px 0 rgba(0,0,0,0.1)"
  },
  freetrial: {
    width: "153px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    boxShadow: "0 10px 20px 0 rgba(0,0,0,0.15)",
    backgroundImage: "linear-gradient(to right, #f45062, #f48293)",
    borderRadius: "22px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 px 0 80px",
    textTransform: "none"
  }
});

class SignedOutLinks extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      trial: false,
      menuOpen: false,
      openTools: false,
      anchorEl: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname)
      this.handleClose();
  }

  handleClose = () => {
    this.setState({
      openTools: false,
      anchorEl: null
    });
  };

  handleOpen = e => {
    e.preventDefault();
    this.setState({
      openTools: true,
      anchorEl: e.currentTarget
    });
  };

  openLogin = () => {
    this.setState({
      open: true
    });
  };

  openTrial = () => {
    this.setState({
      trial: true
    });
  };

  onClose = e => {
    this.setState({ open: false, trial: false });
  };

  menuOpen() {
    this.setState({ menuOpen: true });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { classes, ishome } = this.props;
    const { menuOpen, openTools, anchorEl } = this.state;
    let homePage = false;
    this.props.location.pathname === "/"
      ? (homePage = true)
      : (homePage = false);

    return (
      <div className="row">
        <Login open={this.state.open} onClose={this.onClose} />
        <Trial open={this.state.trial} onClose={this.onClose} />

        <div className="col d-flex flex-row">
          <div className={ishome ? "p-1 menuItem" : "p-1 menuItem1"}>
            {/* <NavLink to="/blog">Blog</NavLink> */}
            {/* <NavLink to="/testimonials">Testimonials</NavLink> */}
          </div>
          {/* <div className={ishome ? "p-1 menuItem" : "p-1 menuItem1"}>
            <NavLink to="/company">Company</NavLink>
          </div> */}
          <div className={ishome ? "p-1 menuItem" : "p-1 menuItem1"}>
            {/* <NavLink to="/company">Company</NavLink> */}
            <NavLink style={{ marginLeft: "30px" }} to="/faq">
              FAQ
            </NavLink>
          </div>
          {/* <div className={ishome ? "p-2 menuItem" : "p-2 menuItem1"}>
            <NavLink to="/company">Company</NavLink>
          </div>
          <div className={ishome ? "p-2 menuItem" : "p-2 menuItem1"}>
            <NavLink to="/testimonials">Testimonials</NavLink>
          </div> */}
          {/* className={
              ishome
                ? "p-2 menuItem btn-floating"
                : "p-2 menuItem1 btn-floating"
            } */}
          <div
            className={
              openTools
                ? ishome
                  ? "p-1 menuItemHomeHover btn-floating"
                  : "p-1 menuItemHover btn-floating"
                : ishome
                ? "p-1 menuItem btn-floating"
                : "p-1 menuItem1 btn-floating"
            }
            style={{ width: "110px" }}
            onClick={this.handleOpen}
          >
            <p className=" ">Free Tools</p>

            {openTools ? (
              <HeaderArrow
                style={
                  homePage
                    ? {
                        height: "18px",
                        fill: "#ffffff",
                        transform: "rotate(180deg)"
                      }
                    : {
                        height: "18px",
                        fill: "#1d48f5",
                        transform: "rotate(180deg)"
                      }
                }
                alt=""
              />
            ) : (
              <HeaderArrow
                style={
                  homePage
                    ? {
                        height: "18px",
                        fill: "#ffffff"
                      }
                    : {
                        height: "18px",
                        fill: "#415089"
                      }
                }
                alt=""
              />
            )}
          </div>
          <Menu
            style={{ marginTop: "78px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={openTools}
            onClose={this.handleClose}
          >
            <MenuItem
              style={{ color: "#1d48f5" }}
              component={Link}
              to="/topic-finder"
            >
              Blogger's Best Friend
            </MenuItem>
            <MenuItem
              component={Link}
              to="/seo-wizard"
              style={{ color: "#1d48f5" }}
            >
              One-Page SEO Wizard
            </MenuItem>
          </Menu>

          <Button
            className={ishome ? classes.signin : classes.signin1}
            onClick={this.openLogin}
          >
            Sign In
          </Button>

          <Button className={classes.freetrial} onClick={this.openTrial}>
            Risk Free Trial
          </Button>
        </div>
        <CheeseburgerMenu
          right={true}
          isOpen={this.state.menuOpen}
          closeCallback={this.closeMenu.bind(this)}
        >
          <MenuContent closeCallback={this.closeMenu.bind(this)} />
        </CheeseburgerMenu>

        {/* <HamburgerMenu
          isOpen={this.state.menuOpen}
          menuClicked={this.openMenu.bind(this)}
          width={32}
          height={24}
          strokeWidth={3}
          rotate={0}
          color="black"
          borderRadius={0}
          animationDuration={0.5}
        /> */}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SignedOutLinks));
